import Page from '../../components/page/Page'
import s from "./Rules.module.scss"

export default function Rule3() {
  return (
    <Page>
      <h1>Rule 3 - Respect Staff</h1>
      <div className={s.content}>
        <span>The staff members are here to keep the peace and this rule exists to help them do that. There is a lot of power in this rule, however, and it is easily
          abused. While we try our best to vet our staff thoroughly to avoid anyone who would abuse this power from making it anywhere near the staff team, it's
          not a full-proof system. If you observe any staff member abusing this rule, please create an Admin-Only ticket in the <span className="link" onClick={() => window.open("https://discord.com/channels/704499874456010763/750139499270635530")}>#create-ticket</span> channel of our <span className="link" onClick={() => window.open("https://discord.gg/mfC2nS8")}>Discord Server</span> to report it.</span>
        <span>As an alternate way of understanding it, rule 3 can be broken down into sub-rules:</span>
        <span><span className={s.subrule}>3.1</span> Don't disrespect staff members. This includes but isn't limited to calling them names, telling them how to do their job,
          arguing back excessively, attacking them while they're trying to enforce the rules, and attempting to manipulate them into doing what you want.</span>
        <span><span className={s.subrule}>3.2</span> Don't make active efforts to prevent staff members from enforcing the rules, such as giving them misleading information.</span>
        <span><span className={s.subrule}>3.3</span> Don't misuse the "/helpop" command. Correct use of this command is only to inform staff members about problems.</span>
      </div>
    </Page>
  )
}
