import React, { useState, useEffect } from 'react';
import Goal from '../Interfaces/Goal';
import s from './GoalCard.module.scss';

import Markdown from 'react-markdown'
import rehypeRaw from 'rehype-raw'

interface GoalCardsProps {
    goals: Goal[];
}

export default function GoalCards(props: GoalCardsProps) {
    return (
        <div className={s.goalCardsContainer}>
            {props.goals.map(goal => GoalCard(goal))}
        </div>
    );
};

function GoalCard(goal: Goal) {
    const [hover, setHover] = useState<boolean>(false);
    const [hoverTimeoutId, setHoverTimeoutId] = useState<number | null>(null);

    const setHovering = () => {
        // Clear any existing timeout to ensure we don't have multiple timeouts running
        if (hoverTimeoutId) {
            clearTimeout(hoverTimeoutId);
        }
        // Set a new timeout and store its ID so we can clear it if necessary
        const id = window.setTimeout(() => {
            setHover(true);
        }, 250);
        setHoverTimeoutId(id);
    };

    const setNotHovering = () => {
        // Clear the timeout if the user stops hovering before the delay is up
        if (hoverTimeoutId) {
            clearTimeout(hoverTimeoutId);
            setHoverTimeoutId(null);
        }
        setHover(false);
    };

    // Effect for cleanup on component unmount
    useEffect(() => {
        return () => {
            // Ensure we clear the timeout when the component unmounts
            if (hoverTimeoutId) {
                clearTimeout(hoverTimeoutId);
            }
        };
    }, [hoverTimeoutId]);

    return (
        <>
            <div className={s.goalParent}>
                <div className={s.goalCard} onMouseEnter={setHovering} onMouseLeave={setNotHovering}>
                    <div className={s.goalName}>{goal.name}</div>
                    <div className={s.goalCardData}>
                        <progress className={s.goalProgressBar} value={goal.percent_complete} max="1" />
                        <div className={s.goalRow}>
                            <div className={s.money}>{goal.complete_f} / {goal.goal_f}</div>
                            <div className={s.percent}>{goal.percent_complete_f}</div>
                        </div>
                    </div>
                </div>
                <div className={s.goalDescription} style={{ opacity: hover ? '1' : '0', zIndex: hover ? 999 : -999 }}>
                    <Markdown rehypePlugins={[rehypeRaw]}>{goal.description}</Markdown>
                </div>
            </div>
        </>
    );
}