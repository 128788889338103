import Page from '../../components/page/Page'
import { useNavigate } from "react-router-dom"
import s from "./Rules.module.scss"

export default function Rules() {
  const nav = useNavigate()

  return (
    <Page>
      <h1>Rules Summary</h1>

      <div className={s.content}>
        <span>This page outlines briefly the 10 rules of the server. Click on a rule to see a more in-depth explanation.</span>

        <span>Most punishments for breaking the rules are listed in our <span className="link" onClick={() => nav("/docs/policies")}>Policies Document</span>.</span>

        <div className={s.rule}>
          <h4 onClick={() => nav("/rules/1")}>Rule 1 - Be Nice</h4>

          <span>Don't insult, bully, harass, scam, grief, socially manipulate, steal from, or attack other players and don't use offensive or excessively vulgar language.</span>
        </div>

        <div className={s.rule}>
          <h4 onClick={() => nav("/rules/2")}>Rule 2 - Don't Cheat</h4>

          <span>Don't hack, use x-ray, use a hacked client, or use a modded client that gives you a significant advantage.</span>
        </div>

        <div className={s.rule}>
          <h4 onClick={() => nav("/rules/3")}>Rule 3 - Respect Staff</h4>

          <span>Don't disrespect staff members, make active efforts to prevent them from enforcing the rules, or misuse the /helpop command. Comply with all reasonable requests they make in effort to enforce the rules, regardless of if you agree with what they say or not.</span>
        </div>

        <div className={s.rule}>
          <h4 onClick={() => nav("/rules/4")}>Rule 4 - Keep it PG-13</h4>

          <span>Don't create, spread or promote extreme examples of non-child friendly content, including but not limited to: Sexual References, Hard Drugs References, and Child abuse References.</span>
        </div>

        <div className={s.rule}>
          <h4 onClick={() => nav("/rules/5")}>Rule 5 - No AFK Farming</h4>

          <span>Don't go AFK next to a farm, use a chunk loader,  or attempt to bypass the 20-minute AFK kick in any way, including coming back once in a while to move around jump, etc.</span>
        </div>

        <div className={s.rule}>
          <h4 onClick={() => nav("/rules/6")}>Rule 6 - Respect the Chat</h4>

          <span>Don't spam, flood the chat with lots of messages at once, advertise anything not related to our server in any server chats, speak languages other than English in global chat, or beg for items or money.</span>
        </div>

        <div className={s.rule}>
          <h4 onClick={() => nav("/rules/7")}>Rule 7 - No Imposters</h4>

          <span>Don't impersonate staff or other players or make profit using other players' creative works without permission.</span>
        </div>

        <div className={s.rule}>
          <h4 onClick={() => nav("/rules/8")}>Rule 8 - No Profiteering</h4>

          <span>- Don't sell in-game items for real money or items from the webstore.</span>

          <span>- Don't use an alt account to bypass limitations such as dynamic /shop pricing or the jobs income limit.</span>

          <span>- Don't deliberately clutter /ah with listings that nobody will buy.</span>

          <span>- If you hire someone for a task, you must write down the deal and ensure both parties have a copy of the agreement. The easiest way to accomplish this is with /agreements.</span>

          <span>- Loans of in-game currency where the lender stands to make profit from the loan aren't protected under Rule 1's scamming clause. The staff team reserves the right to reverse in entirety any loans they deem unfair or predatory in nature.</span>
        </div>

        <div className={s.rule}>
          <h4 onClick={() => nav("/rules/9")}>Rule 9 - Don't Exploit</h4>

          <span>Don't abuse bugs, glitches, exploits, or mishaps in /shop pricing and alert a staff member if you discover one.</span>
        </div>

        <div className={s.rule}>
          <h4 onClick={() => nav("/rules/10")}>Rule 10 - Be a Good Citizen</h4>

          <span>Don't start a build within 10 chunks of someone else's without their permission and follow the rules and guidelines specified in the <span className="link" onClick={() => nav("/docs/townhandbook")}>Town Handbook</span>.</span>
        </div>
      </div>
    </Page>
  )
}
