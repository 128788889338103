import React, { useState, useEffect } from 'react';
import Page from '../../components/page/Page'
import s from "./Info.module.scss"

interface StaffMember {
  name: string;
  rank: string;
  uuid: string;
}

function getColorFor(rank: String) {
  switch (rank.toLowerCase()) {
    case 'jrmod':
    case 'mod':
    case 'srmod':
      return 'gold';
    case 'jradmin':
    case 'admin':
    case 'sradmin':
    case 'hdadmin':
      return 'red';
  }
  return 'none';
}

export default function Staff() {
  const [staffRows, setStaffRows] = useState<StaffMember[][]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const staffDataURL = 'https://atownyserver.com/resources/staff.json';

    fetch(staffDataURL)
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then(data => {
        console.log("Fetched data:", data); // Log the fetched data
        setStaffRows(data);
        setIsLoading(false);
      })
      .catch(error => {
        setError(error.message);
        setIsLoading(false);
      });
  }, []);
  // member.uuid.replace('-','')
  return (
    <Page>
      <h1>Staff</h1>
      <div className={s.content}>
        <h3>Staff Members</h3>
        {staffRows.map((row, rowIndex) => (
          // Map over each row to create a staff line
          <div key={rowIndex} className={`${s.horizontalAlign}`}>
            {row.map(member => (
              // Map over each member in the row to create their card
              <div key={member.name} className={s.staffMember}>
                <img alt='Face' src={`https://mc-heads.net/avatar/${member.name}/32`} />
                <div className={s.staffMemberText}>
                  <span>{member.name}</span>
                  <span className={s[getColorFor(member.rank)]}>{member.rank}</span>
                </div>
              </div>
            ))}
          </div>
        ))}
        <div className={s.rightAlign}>
          <div className={s.staffMember}>
            <img alt="" src="https://mc-heads.net/avatar/4fcdc4069b5444728c8c926609fb4687/32" />
            <div className={s.staffMemberText}>
              <span>Inceris</span>
              <span style={{ "color": "red" }}>Founder</span>
            </div>
          </div>
        </div>

        <div className={s.group}>
          <h3>Staff Ranks</h3>
          <span>Our server's staff members are organised into ranks. In most cases, later ranks inherit the responsibilties of the ones before it as well as the ones
            from their current rank.
          </span>
        </div>

        <div className={s.group}>
          <h4 className={s.gold}>JrMod</h4>
          <span>Junior Moderators keep the server chat held to the rules and answer questions from players.</span>
        </div>

        <div className={s.group}>
          <h4 className={s.gold}>Mod</h4>
          <span>Fully-fledged Moderators can deal with hackers and investigate griefs.</span>
        </div>

        <div className={s.group}>
          <h4 className={s.gold}>SrMod</h4>
          <span>Senior Moderators can roll-back griefs, restore inventories, and return stolen items.</span>
        </div>

        <div className={s.group}>
          <h4 className={s.gold}>HdMod</h4>
          <span>Head Moderators help manage and train the moderators of the staff team. Unlike the other ranks, admins don't by default inherit this rank's
            responsibilities.
          </span>
        </div>

        <div className={s.group}>
          <h4 className={s.red}>JrAdmin</h4>
          <span>Junior Administrators can resolve town and nation-related issues and directly manage player items and currency where needed.</span>
        </div>

        <div className={s.group}>
          <h4 className={s.red}>Admin</h4>
          <span>Fully-fledged Administrators have all in-game permissions and are trusted to handle almost any problem.</span>
        </div>

        <div className={s.group}>
          <h4 className={s.red}>SrAdmin</h4>
          <span>Senior Administrators have access to the machine the server runs on. They have the tools to solve any server-related problem including fixing complex
            bugs. Senior admins are also authorised to implement new features and change existing ones without the permission of the head admin.</span>
        </div>

        <div className={s.group}>
          <h4 className={s.red}>HdAdmin</h4>
          <span>The head administrator, Heliosares, is the server owner. He manages the staff team and has the final say on server-related decisions.</span>
        </div>

        <div style={{ "alignItems": "center" }} className={s.tips}>
          <span>If you think you'd be a good fit for our team, you're welcome to apply here:</span>
          <a href="/apply" className="link">Staff Application</a>
        </div>
      </div>
    </Page>
  )
}
