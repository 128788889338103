import Page from '../../components/page/Page'
import s from "./GettingStarted.module.scss"
import { useNavigate } from "react-router-dom"

export const GettingStarted = () => {
  const nav = useNavigate()

  return (
    <Page>
      <h1>Getting Started</h1>
      <div className={s.content}>
        <h4>Introduction</h4>
        <span>Welcome to our guide to getting started on A Towny Server! This is a brief guide that explains the surface-level of the mechanics of our server. You can find much more in depth information on this website and by using <span className={s.command}>/info</span> in-game or browsing this site.</span>
        <div />
        <h4>What We're About</h4>
        <span>We are a community-focused towny economy server. This means the main objective is to get together with other players to build a beautiful town together!</span>
        <span>We also have an economy system where you can get <span className="link" onClick={() => nav("/info/jobs")}>Jobs</span> and do <span className="link" onClick={() => nav("/info/quests")}>Quests</span> to earn in-game currency. You'll need this currency to do things in the game like create a town, engage with the player market, and get special perks via <span className="link" onClick={() => nav("/info/ranks")}>in-game ranks</span>!</span>
        <div />
        <h4>Before You Play</h4>
        <span>Please read the <span className="link" onClick={() => nav("/rules")}>Rules</span> before you start playing. You don't need to memorise them; just read the title of each rule and skim its contents so you know what's okay and what isn't.</span>
        <div />
        <h4>How You Start Playing</h4>
        <span>After spawning in, you can start playing by going through the big portal directly in-front of the spawn platform or by using <span className={s.command}>/rtp</span>.</span>
        <div />
        <h4>How do I ...?</h4>
        <span><span className={s.command}>Join a Town:</span> Makes friends with someone and then ask them!</span>
        <span><span className={s.command}>Make a Town:</span> Use <span className={s.command}>/t new</span>. Make sure you read <span className={s.command}>/townybasics</span> to get an understanding of how this works!</span>
        <span><span className={s.command}>Earn In-Game Currency:</span> The easiest way is via <span className="link" onClick={() => nav("/info/jobs")}>Jobs</span> and <span className="link" onClick={() => nav("/info/quests")}>Quests</span>. Read <span className={s.command}>/economy</span> to learn more!</span>
        <span><span className={s.command}>Do X in my Town/Nation:</span> Have a read of <span className={s.command}>/townyhelp</span> for the answers to all your town/nation related questions!</span>
        <div />
        <h4>Still Need Help?</h4>
        <span>Our staff and playerbase are always happy to give you advice. Just ask in-game or on our <a href="https://discord.gg/mfC2nS8" className={"link"}>Discord</a> server!</span>
      </div>
    </Page>
  )
}
