import React, { useEffect, useState } from 'react';
import s from './PassGen.module.scss';

const charactersLower = "abcdefghijklmnopqrstuvwxyz";
const charactersUpper = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
const charactersNumbers = "0123456789";
const charactersSymbols = "!@#$%^&*()_+-=[]{}|;':,.<>?/";

function secureRandom() {
    var buf = new Uint32Array(2);
    crypto.getRandomValues(buf);
    return (buf[0] + buf[1] * 2 ** 32) / (2 ** 64);
}

function generatePassword(length: number, includeSymbols: boolean) {
    const charset = charactersLower + charactersUpper + charactersNumbers + (includeSymbols ? charactersSymbols : "");
    let newPassword = "";
    for (let i = 0; i < length; i++) {
        const randomIndex = Math.floor(secureRandom() * charset.length);
        newPassword += charset[randomIndex];
    }
    return newPassword;
}

export default function PassGen() {
    const [password, setPassword] = useState<string | undefined>();
    const [length, setLength] = useState(20);
    const [includeSymbols, setIncludeSymbols] = useState(true);

    function copyPassword() {
        if (!password) return;
        navigator.clipboard.writeText(password).catch(err => {
            console.error('Error in copying text: ', err);
        });
    }

    useEffect(() => {
        setPassword(generatePassword(length, includeSymbols));
    }, [includeSymbols, length]);

    return <div className={s.container}>
        <div className={s.spacer}></div>

        <div className={s.card}>
            <div className={s.header}>Password Generator</div>
                <label className={s.line}>Password Length:
                    <input
                        type="range"
                        id="length"
                        name="length"
                        min="8"
                        max="64"
                        value={length}
                        onChange={e => {
                            e.preventDefault();
                            setLength(Number(e.target.value));
                        }}
                    />
                    <span className={s.whiteText}>{length}</span>
                </label>
            <label className={s.whiteText}>
                <input
                    type="checkbox"
                    id="includeSymbols"
                    onChange={e => {
                        e.preventDefault();
                        setIncludeSymbols(e.target.checked);
                    }}
                    checked={includeSymbols}
                /> Include Symbols</label>

            <code className={s.password}>{password}</code>

            <div className={s.buttons}>
                <button onClick={() => setPassword(generatePassword(length, includeSymbols))}>
                    <div className={s.content}>
                        <div className={s.whiteText}>⟳</div>
                        <div className={s.whiteText}>Refresh</div>
                    </div>
                </button>
                <button onClick={() => copyPassword()}>
                    <div className={s.content}>
                        <div className={s.whiteText}>⎘</div>
                        <div className={s.whiteText}>Copy</div>
                    </div>
                </button>
            </div>
        </div>
    </div>
}