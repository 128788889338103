import { ReactNode } from "react"
import { useNavigate } from "react-router-dom"
import s from "./Layout.module.scss"
import dropdownArrow from "../images/dropDownArrow.svg"

type Props = {
  children: ReactNode
}

export default function Layout({ children }: Props) {
  const nav = useNavigate();

  const voteSites = {
    "BestServers": "https://minecraftbestservers.com/server-a-towny-server.1109",
    "TopG": "https://topg.org/minecraft-servers/server-634802",
    "PlanetMinecraft": "https://www.planetminecraft.com/server/a-towny-server-6114247/"
  };

  return (
    <div className={s.layout}>
      <nav>
        <button className={s.highlightHover} onClick={() => nav("/")}>HOME</button>
        <div className={s.dropdown}>
          <div className={s.category}>
            <span>RULES</span>
            <img alt="" src={dropdownArrow} className={s.arrowIcon} />
          </div>
          <div className={s.content}>
            <span onClick={() => { nav("/rules") }}>Rules Summary</span>
            <span onClick={() => { nav("/rules/1") }}>1 - Be Nice</span>
            <span onClick={() => { nav("/rules/2") }}>2 - Don't Cheat</span>
            <span onClick={() => { nav("/rules/3") }}>3 - Respect Staff</span>
            <span onClick={() => { nav("/rules/4") }}>4 - Keep it PG-13</span>
            <span onClick={() => { nav("/rules/5") }}>5 - No AFK Farming</span>
            <span onClick={() => { nav("/rules/6") }}>6 - Respect the Chat</span>
            <span onClick={() => { nav("/rules/7") }}>7 - No Imposters</span>
            <span onClick={() => { nav("/rules/8") }}>8 - No Profiteering</span>
            <span onClick={() => { nav("/rules/9") }}>9 - Don't Exploit</span>
            <span onClick={() => { nav("/rules/10") }}>10 - Be a Good Citizen</span>
          </div>
        </div>
        <div className={s.dropdown}>
          <div className={s.category}>
            <span>INFO</span>
            <img alt="" src={dropdownArrow} className={s.arrowIcon} />
          </div>
          <div className={s.content}>
            <span onClick={() => { nav("/info/commands") }}>Commands</span>
            <span onClick={() => { nav("/info/ranks") }}>Ranks</span>
            <span onClick={() => { nav("/info/jobs") }}>Jobs</span>
            <span onClick={() => { nav("/info/quests") }}>Quests</span>
            <span onClick={() => { nav("/info/elytra") }}>Elytra</span>
            <span onClick={() => { nav("/info/potm") }}>PotM</span>
            <span onClick={() => { nav("/info/staff") }}>Staff</span>
            <span onClick={() => { nav("/info/dynamicpricing") }}>Dynamic Pricing</span>
            <span onClick={() => { nav("/info/wishes") }}>Wishes</span>
          </div>
        </div>
        <div className={s.dropdown}>
          <div className={s.category}>
            <span>DOCS</span>
            <img alt="" src={dropdownArrow} className={s.arrowIcon} />
          </div>
          <div className={s.content}>
            <span onClick={() => { nav("/docs/townhandbook") }}>Town Handbook</span>
            <span onClick={() => { nav("/docs/policies") }}>Policies</span>
          </div>
        </div>
        <div className={s.dropdown}>
          <div className={s.category}>
            <span>VOTE</span>
            <img alt="" src={dropdownArrow} className={s.arrowIcon} />
          </div>
          <div className={s.content}>
            {Object.entries(voteSites).map(site => <span onClick={() => { window.open(site[1]) }}>{site[0]}</span>)}
            <span onClick={() => { Object.entries(voteSites).forEach(site => window.open(site[1])); }}>* Open All</span>
          </div>
        </div>
        <div className={s.dropdown}>
          <div className={s.category}>
            <span>DYNMAP</span>
            <img alt="" src={dropdownArrow} className={s.arrowIcon} />
          </div>
          <div className={s.content}>
            <span onClick={() => { nav("/dynmap?server=archives") }}>The Archives</span>
            <span onClick={() => { nav("/dynmap?server=blossom") }}>Blossom</span>
            <span onClick={() => { nav("/dynmap?server=creative") }}>Creative</span>
          </div>
        </div>
        <button className={s.highlightHover} onClick={() => window.open("https://discord.gg/mfC2nS8")}>DISCORD</button>
        <button className={s.highlightHover} onClick={() => nav("/store")}>STORE</button>
        <button className={s.highlightHover} onClick={() => nav("/new")}>NEW HERE?</button>
      </nav>
      {children}
    </div>
  )
}
