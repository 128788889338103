import Page from '../../components/page/Page'
import s from "./Info.module.scss"
import sWishes from "./Wishes.module.scss"
import check from "../../images/check.svg"
import x from "../../images/x.svg"

export default function Wishes() {
  const tdX = <td><div className={sWishes.center}><img src={x} alt={"No"} className={sWishes.iconRed}/></div></td>;
  const tdCheck = <td><div className={sWishes.center}><img src={check} alt={"Yes"} className={sWishes.iconGreen}/></div></td>;
  return (
    <Page>
      <h1>Wishes</h1>
      <div className={s.content}>
        <span>Wishes are a way to have things done in the game that would normally not be possible. There are two main types of wishes, Webstore and Fancy.</span>
        <h3>Webstore Wishes</h3>
        <span>Webstore wishes are constrained by Mojang's <a href={"https://www.minecraft.net/en-us/usage-guidelines"}>Usage Guidelines</a> and can not do anything that would provide any kind of in-game advantage. They can only do things that are purely cosmetic. (see the examples below)</span>
        <h3>Fancy Wishes</h3>
        <span>Fancy wishes cannot be obtained with IRL money, so they can do things that provide an in-game advantage. They are generally limited to an in-game monetary value of $100k, but they may be sold player-to-player for any amount of money. Just make an agreement with the other person with /propose, then message me on Discord or mail me in-game to let me know to transfer it.</span>
        <span>If you are redeeming a Fancy wish for something that has any sort of monetary value assigned to it, it typically cannot exceed that $100k limit (in-game rank up, items being spawned, etc.)</span>
        <h3>Wish Examples</h3>
        <table className={sWishes.table}>
          <tr>
            <th><div className={sWishes.center}>Description</div></th>
            <th><div className={sWishes.center}>Webstore Wish</div></th>
            <th><div className={sWishes.center}>Fancy Wish</div></th>
          </tr>
          <tr>
            <td><div className={sWishes.center}>$12.50 webstore credit</div></td>
            {tdCheck}
            {tdCheck}
          </tr>
          <tr>
            <td><div className={sWishes.center}>Have a custom NPC created that can have custom text in chat when someone walks close to it</div></td>
            {tdCheck}
            {tdCheck}
          </tr>
          <tr>
            <td><div className={sWishes.center}>A custom prefix in place of your player/donor rank prefix (within reason)</div></td>
            {tdCheck}
            {tdCheck}
          </tr>
          <tr>
            <td><div className={sWishes.center}>Move something with WorldEdit (No creation of materials)</div></td>
            {tdCheck}
            {tdCheck}
          </tr>
          <tr>
            <td><div className={sWishes.center}>Custom Brewery Recipe (Without Beneficial Effects)</div></td>
            {tdCheck}
            {tdCheck}
          </tr>

          <tr>
            <td><div className={sWishes.center}>Custom Brewery Recipe (With Beneficial Effects)</div></td>
            {tdX}
            {tdCheck}
          </tr>
          <tr>
            <td><div className={sWishes.center}>Clear an area with WorldEdit</div></td>
            {tdX}
            {tdCheck}
          </tr>
          <tr>
            <td><div className={sWishes.center}>Biome Change</div></td>
            {tdX}
            {tdCheck}
          </tr>
          <tr>
            <td><div className={sWishes.center}>More Claims, Homes, Jobs, or similar kind of increase to an in-game limitation</div></td>
            {tdX}
            {tdCheck}
          </tr>
          <tr>
            <td><div className={sWishes.center}>Material such as animals, money, or a custom item</div></td>
            {tdX}
            {tdCheck}
          </tr>
          <tr>
            <td><div className={sWishes.center}>Increase an enchantment 1 level above the limit</div></td>
            {tdX}
            {tdCheck}
          </tr>
          <tr>
            <td><div className={sWishes.center}>Non-vanilla enchant such as Retrieving, Soulbound, Unbreakable, or Replanting</div></td>
            {tdX}
            {tdCheck}
          </tr>
          <tr>
            <td><div className={sWishes.center}>Get a void world 3k x 3k all to yourself!</div></td>
            {tdX}
            {tdCheck}
          </tr>
        </table>
      </div>
    </Page>
  )
}
