import Page from "../../components/page/Page"
import sInfo from "../Info/Info.module.scss"
import s from "./Terms.module.scss"

export default function Terms() {
    return <Page>
        <h1>Terms of Service</h1>
        <div className={sInfo.content}>
            <div className={s.subtitle}>Introduction</div>

            <p>Thank you for considering supporting A Towny Server. By making a purchase, you acknowledge and agree to the following Terms of Service (the "Terms").</p>
            <p>Please read these Terms carefully before making any purchase.</p>

            <p>All purchases are voluntary and help with the upkeep and enhancement of A Towny Server.</p>
            <p>NOT AN OFFICIAL MINECRAFT PRODUCT. NOT APPROVED BY OR ASSOCIATED WITH MOJANG OR MICROSOFT</p>

            <div className={s.subtitle}>Age and Permission</div>

            <p>By making a purchase, you declare that:</p>
            <ul>
                <li>You are an adult (18 or older) and are donating with your own funds, or with funds you have permission to use; or</li>
                <li>you are a minor (under 18) and have received parent or guardian permission to make the purchase using funds which the parent or guardian has permission to use.</li>
            </ul>

            <div className={s.subtitle}>No Refunds</div>

            <p>All purchases are final and non-refundable. Please double-check the amount before making a purchase.</p>
            <p>Initiating a chargeback or dispute with your bank or payment provider over a purchase you made to us will result in a permanent ban of your account and IP address from A Towny Server until the issue is resolved,
                as determined by A Towny Server. We reserve the right to pursue legal action against fraudulent chargebacks.</p>

            <div className={s.subtitle}>Termination of Access</div>

            <p>Donating does not grant immunity from server rules.
                If you violate our community guidelines or server rules, your punishment will be handled the same as a player who did not donate,
                and you may lose access to the perks associated with any purchase, no refunds will be issued, and any resulting chargebacks will be considered fraudulent.
                See "No Refunds" above for information regarding fraudulent chargebacks.</p>

            <div className={s.subtitle}>Data and Privacy</div>

            <p>We respect your privacy. Any information provided during the purchase process will be kept confidential and will only be used for the purpose of processing the purchase and providing any associated rewards.</p>

            <div className={s.subtitle}>Modification of Terms</div>

            <p>We reserve the right to change, modify, or revise these Terms at any time. Your continued use of the website or A Towny Server following the posting of changes to these Terms implies you accept those changes.
                Changes will be communicated via Discord in #changelog.
            </p>

            <p>We reserve the right to change, modify, or remove rewards or perks without notice, to include those associated with previous purchases.</p>

            <div className={s.subtitle}>Limitation of Liability</div>

            <p>By donating, you agree that A Towny Server and its owner(s), staff, and affiliates shall not be held liable for any unforeseen circumstance or any harm,
                damage, or loss incurred as a result of using our services or making purchases.</p>

            <div className={s.subtitle}>Contact</div>

            <p>If you have any questions about these Terms or about your purchase, please contact me in any of the following ways:
                <ul>
                    <li>Private message `ks_hl` on Discord</li>
                    <li>Mail me in-game with `/mail Heliosares [msg]`</li>
                    <li>Create a ticket in #createticket on the <a href="/discord">Discord server</a>.</li>
                    <li>As a last resort, email atownyservermc@gmail.com. This email is not checked frequently.</li>
                </ul></p>

            By proceeding with your purchase, you are confirming that you have read, understood, and agree to these Terms.

            <p>Last Updated: 25-Apr-24</p>
        </div>
    </Page>
}