import Page from '../../components/page/Page'
import s from "./Info.module.scss"
import recentprices from "../../images/recentprices.png"
import dynamicgraph from "../../images/dynamicgraph.png"
import dynamicfunction from "../../images/dynamicfunction.png"

export default function DynamicPricing() {
  return (
    <Page>
      <h1>Dynamic Pricing</h1>
      <div className={s.content}>
        <span>The more of an item you sell, the less money you will make from it, temporarily.</span>
        <span>The amount your sell price is reduced by is based on how much money you've earned from that item <span style={{ fontWeight: 700 }}>recently</span>. You can see this number by doing <span className={s.command}>/worth [item]</span>.</span>
        <img src={recentprices} style={{ height: 'auto', width: '50%', alignSelf: 'center' }} loading="lazy" />
        <span>Once your recent profit reaches $2500, your sell price starts to drop. The drop is slow to start, then quickly drops after about $6000. Every IRL hour, on the hour, this total decays by 2.85%, for a total of 50% per 24 hours.</span>
        <span>Note: It's against the rules to use an alt account to bypass dynamic pricing!</span>
        <div className={s.nerdAlert}>
          <h4>Nerd Statistics</h4>
          <span>Your sell price is calculated with the following function. The X-axis is your recent profit, and the Y-axis is the factor your sell price is multiplied by.</span>
          <img src={dynamicgraph} />
          <span>And if you're feeling extra nerdy, here's the function. I'm not sorry for how overcomplicated it is; it's pretty. </span>
          <img src={dynamicfunction} style={{ height: 'auto', width: '50%' }} loading="lazy" />
        </div>
      </div>
    </Page>
  )
}
