import Package from "../Interfaces/Package"
import Card from "./Card"
import s from "./Package.module.scss"
import BackButton from "./BackButton"

import Markdown from 'react-markdown'
import rehypeRaw from 'rehype-raw'
import { MouseEventHandler } from "react"

interface Props {
    pkg: Package;
    goBack: () => void;
    currency: String | null;
    buyForm: React.JSX.Element;
    resetUsername?: MouseEventHandler<HTMLButtonElement>;
}

/**
 * For displaying an individual package and its details
 * 
 * @param props 
 * @returns 
 */
export default function PackageViewer(props: Props) {
    return (<>
        <div className={`${s.packagesGrid} ${s.packagesGridViewer}`}>
            <div className={s.packageDescription}>
                <div className={s.packageViewerHeader}>
                    <h2 className={s.centerText} style={{ color: `${props.pkg.color || '#000000'}` }}>{props.pkg.name}</h2>
                    {props.pkg.category.packages.length > 1 && <BackButton onClick={props.goBack} />}
                </div>
                <h3>You will get:</h3>
                <Markdown rehypePlugins={[rehypeRaw]} >{props.pkg.description}</Markdown>
                <Markdown rehypePlugins={[rehypeRaw]} >{props.pkg.features}</Markdown>

                {props.pkg.category.sequential_upgrades && props.pkg.category.packages.map((pkg: Package) => {
                    if (pkg.index >= props.pkg.index) return <></>;
                    return <>
                        <h3>+ Everything From <span style={{ color: `${pkg.color}` }}>{pkg.name}</span></h3>
                        <Markdown rehypePlugins={[rehypeRaw]}>
                            {pkg.features}
                        </Markdown>
                    </>;
                })}
            </div>
            <div>
                <Card pkg={props.pkg} packageDetails={true} currency={props.currency} color={props.pkg.color_hover} buyForm={props.buyForm} resetUsername={props.resetUsername}/>
            </div>
        </div>
    </>);
}