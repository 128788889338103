import Page from '../../components/page/Page'
import { useEffect, useState } from "react"
import s from "./Homepage.module.scss"

export default function Homepage() {

  interface ServerStatus {
    online: boolean
    players: {
      max: number
      now: number
    }
  }

  const [status, setStatus] = useState<ServerStatus>()

  useEffect(() => {
    fetch("https://mcapi.us/server/status?ip=play.atownyserver.com")
      .then((response) => response.json())
      .then((data) => setStatus(data))
  }, [])

  return (
    <Page>
      <div className={s.group}>
        <h1>Welcome to A Towny Server!</h1>
        <h4>Use the navigation bar at the top to navigate to a page.</h4>
      </div>
      <div className={s.group}>
        <h2>Join us in-game at:</h2>
        <h4 className={s.ip}>play.atownyserver.com</h4>
      </div>
      <div className={s.status}>
        {status ? <>
          {status?.online ? <h4 className={s.online}>Server is online now!</h4>
            : <h4 className={s.offline}>Server is currently offline.</h4>}
          {status?.online ? <span className={s.players}>There are {status.players.now}/{status.players.max} players online</span>
            : <span className={s.players}>Please check <a className="link" href="https://discord.gg/mfC2nS8">Discord</a> #announcements for more info</span>}
        </> : <span>Pinging server...</span>}
      </div>
      <div className={s.content}>
        <span>A Towny Server is a Java edition server that delivers a close-to-vanilla experience enhanced with Towny and a few economy plugins!</span>
        <span>Our small community of dedicated people are ready to welcome you with cosy and friendly vibes and will be happy to welcome you into the borders of a beautiful town!</span>
        <span>To keep things simple while still encouraging interaction within the community, we have a player-driven economy where players are encouraged to buy and sell helpful things to one another. You can earn money by mining, farming, brewing, building or your other favourite in-game activity!</span>
        <span>When you join, you'll be welcomed by the cosy spawn that we built ourselves, greeted by a community that's excited to have you!</span>
        <span>We also have lots of cosmetics! Hats, Particle Effects, Gadgets, and more, all available for everyone to get their hands on!</span>
        <span>Naturally, everyone needs something to aim towards - which is why we've got ranks you can buy with in-game currency! These can earn you extra claims, jobs, backpacks and cool commands!</span>
        <span>We also sport a whole host of quality-of-life features such as: skipping to daytime, returning to your death location, and locking your containers! We also run an anti-cheat to keep everybody playing fair.</span>
        <span>On the topic of playing fair, we operate on a strictly no pay-to-win basis, meaning everyone is on a level playing field. People who donate have no advantages over those who don't, so everyone can have the same great experience!</span>
        <span>Thank you for reading through and we hope to see you in-game!</span>
      </div>
    </Page>
  )
}
