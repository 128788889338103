import Page from '../../components/page/Page'
import { useNavigate } from "react-router-dom"
import s from "./Rules.module.scss"

export default function Rule10() {
  const nav = useNavigate()

  return (
    <Page>
      <h1>Rule 10 - Be a Good Citizen</h1>
      <div className={s.content}>
        <span>As an alternate way of understanding it, rule 10 can be broken down into sub-rules:</span>
        <span><span className={s.subrule}>10.1</span> Don't build on or claim land within 10 chunks of someone else's build or claimed land without their permission.</span>
        <span><span className={s.subrule}>10.2</span> Follow the rules and guidelines in the <span className="link" onClick={() => nav("/docs/townhandbook")}>Town Handbook</span><span>, especially if you're a town mayor.</span></span>
      </div>
    </Page>
  )
}
