import Discount from "../Interfaces/Discount";
import s from "./DiscountBanner.module.scss";

interface Props {
    discounts?: Discount[];
}

export default function DiscountBanner(props: Props) {
    return <>{props.discounts && props.discounts.map(discount =>
        <div className={s.discountCard}>
            <div className={s.discountTitle}>{discount.name}</div>
            <div className={s.discountDescription}>{discount.description && discount.description}</div>
            <div className={s.discountTime}>Now through {new Date(discount.stop_f).toLocaleDateString()}</div>
        </div>
    )}
    </>;
}