import s from "./BackButton.module.scss"
import { useNavigate } from 'react-router-dom';
import dropdownArrow from "../../../images/dropDownArrow.svg"

interface Props {
    onClick?: () => void;
    backTo?:string;
}

export default function BackButton(props:Props) {
    const navigate = useNavigate();
    return (
        <div className={s.backButton} 
            onClick={() => {
                if (props.onClick) {
                    props.onClick();
                } else if (props.backTo) {
                    navigate(props.backTo);
                }
            }}
        >
            <img src={dropdownArrow}/>
        </div>
    );
}