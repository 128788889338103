import React, { useEffect, useState } from 'react';
import Markdown from 'react-markdown'
import rehypeRaw from 'rehype-raw'
import Page from '../../components/page/Page';
import s from "./Store.module.scss";
import sPack from "./Components/Package.module.scss";
import { useLocation, useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import gridIcon from "../../images/grid.png";
import listIcon from "../../images/list.png";
import Package from "./Interfaces/Package";
import Category from "./Interfaces/Category";
import Goal from "./Interfaces/Goal";
import GoalCard from "./Components/GoalCard";
import View from "./Components/View";
import PackageGrid from './Components/PackageGrid';
import PackageViewer from "./Components/PackageViewer";
import BackendResponse from './Components/BackendResponse';
import DiscountBanner from './Components/DiscountBanner';
import Discount from "./Interfaces/Discount";

const development: boolean = false;
const endpoint: string = development ? 'http://localhost:8071' : '/awbapi';

export default function Store() {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const initialCategoryID: number = +(queryParams.get('cat') ?? 0);
  const selectedPackageID: number = +(queryParams.get('pkg') ?? 0);

  const [categories, setCategories] = useState<Category[]>([]);
  const [goals, setGoals] = useState<Goal[]>([]);
  const [selectedCategory, setSelectedCategory_] = useState<Category | undefined>();
  const [selectedPackage, setSelectedPackage_] = useState<Package | undefined>();
  const [currency, setCurrency] = useState<String | null>(null);
  const [failedToLoad, setFailedToLoad] = useState<string | undefined>();
  const [username, setUsername] = useState<string | undefined>(Cookies.get('username'));
  const [validatedUsername, setValidatedUsername] = useState<string | undefined>(Cookies.get('username'));
  const [formPopupMessage, setFormPopupMessage] = useState<string>('');
  const [isListView, setListView] = useState(true);
  const [coupon, setCoupon] = useState<string | undefined>();
  const [validatedCoupon, setValidatedCoupon] = useState<string | undefined>();
  const [agreeToTerms, setAgreeToTerms] = useState(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [quantity, setQuantity] = useState<number>(1);
  const [discounts, setDiscounts] = useState<Discount[]>();

  const fetchDiscounts = () => {
    console.log('Requesting discounts');
    if (discounts) return;
    fetch(endpoint + "/discounts/list")
      .then(response => response.json())
      .then(data => {
        setDiscounts(data.discounts);
      })
      .catch(err => {
        console.log(err.message || "An unknown error occurred.");
      });
  }

  const fetchPackages = (currentCoupon?: string, currentUsername?: string) => {
    setFailedToLoad(undefined);

    let url = endpoint + "/getpackages";
    let first = true;
    if (currentUsername && currentUsername.length > 0) {
      url += "?user=" + currentUsername;
      first = false;
    }
    if (currentCoupon && currentCoupon.length > 0) {
      url += first ? '?' : '&';
      url += "code=" + currentCoupon;
    }

    return new Promise((resolve: (data: BackendResponse) => void, reject: (error: string) => void) => {
      fetch(url)
        .then(response => response.json())
        .catch(err => {
          reject(err.message || "An unknown error occurred.");
        })
        .then(data => {
          if (data.error) {
            console.log(`Error on package request: ${data.error}`);
            reject(data.error);
          } else if (!data.categories) {
            console.log("Response contained no error message and no categories.");
            reject("Server temporarily unavailable");
          } else {
            setCurrency(data.currency);
            setCategories(data.categories);
            setGoals(data.goals);
            let selectedCat: Category | undefined = undefined;
            if (selectedCategory) {
              setSelectedCategory_(selectedCat = data.categories.find((cat: Category) => cat.id === selectedCategory.id));
              if (!selectedCat) {
                console.warn("Failed to find selected category from new categories.");
              }
            }
            console.log(`selectedCategory=${selectedCategory}`);
            if (selectedPackage) {
              const pkg = getPackageByID(data.categories, selectedPackage.id);
              if (!pkg) {
                console.warn("Failed to find selected package from new packages.");
              }
              console.log(`setSelectedPackage_(${pkg})`);
              setSelectedPackage_(pkg);
            }
            setValidatedCoupon(currentCoupon);
            setUsername(data.username);
            setValidatedUsername(data.username);
            data.categories.forEach((cat: Category) => {
              cat.packages.forEach((pack: Package, index: number) => {
                pack.category = cat;
                pack.index = index;
              });
            });
            const resp: BackendResponse = { currency: data.currency, categories: data.categories, username: data.username };
            console.log(`response user: ${resp.username}`);
            resolve(resp);
          }
        });
    })
  };

  const checkout = (pkg: number, name: string, code?: string, sub?: boolean) => {
    let params = new URLSearchParams({
      user: name,
      package: pkg.toString(), // Convert number to string
      ...(code ? { code: code } : {}), // Only add `code` if it exists
      ...(sub ? { subscribe: sub.toString() } : {}), // Convert boolean to string and only add `subscribe` if it exists
      ...(quantity > 1 ? { quantity: quantity.toString() } : {}) // Only add `quantity` if greater than 1
    }).toString();

    let url = `${endpoint}/checkout?${params}`;

    setLoading(true);
    return new Promise((resolve: (location: string) => void, reject: (error: string) => void) => {
      fetch(url)
        .then(response => response.json())
        .catch(err => {
          setLoading(false);
          reject(err.message || "An unknown error occurred.");
        })
        .then(data => {
          setLoading(false);
          if (data.error) {
            console.log(`Error on checkout request: ${data.error}`);
            reject(data.error);
          } else if (data.location) {
            window.location.href = data.location;
            resolve(data.location);
          } else {
            reject("An unknown error occurred.");
          }
        });
    });
  };

  const getCategoryByID: (categories: Category[], id: number) => Category | undefined = (categories: Category[], id: number) => {
    for (let catID = 0; catID < categories.length; catID++) {
      const cat = categories[catID];
      if (cat.id === id) {
        return cat;
      }
    }
  }

  const getPackageByID: (categories: Category[], id: number) => Package | undefined = (categories: Category[], id: number) => {
    for (let catID = 0; catID < categories.length; catID++) {
      const cat = categories[catID];
      for (let pkgID = 0; pkgID < cat.packages.length; pkgID++) {
        const pkg: Package = cat.packages[pkgID];
        if (pkg.id === id) {
          return pkg;
        }
      }
    }
  }

  const setFormPopup = (msg: string, duration: number) => {
    setFormPopupMessage(msg);
    if (msg.length === 0) return;
    (async () => {
      const timeout = setTimeout(() => setFormPopupMessage(''), duration)
      return () => clearTimeout(timeout)
    })();
  };

  const setSelectedPackage = (newPackage: Package | undefined) => {
    console.log(`set selected package to ${newPackage?.name}`);
    if (newPackage) {
      navigate(`?pkg=${newPackage.id}`)
    } else {
      navigate('');
    }
    setSelectedPackage_(newPackage);
  };

  const setSelectedCategory = (newCategory: Category | undefined) => {
    setSelectedCategory_(newCategory);
    console.log(`set selected category to ${newCategory?.name}`);
    if (newCategory && newCategory.packages.length === 1) {
      setSelectedPackage(newCategory.packages[0]);
      return;
    } else {
      setSelectedPackage_(undefined);
    }
    if (newCategory) {
      navigate(`?cat=${newCategory.id}`);
      setListView(newCategory.default_view === View.LIST);
    } else {
      navigate('');
    }
  }

  useEffect(() => {
    fetchPackages(undefined, username).then((data: BackendResponse) => {
      if (selectedPackageID) {
        const pkg: Package | undefined = getPackageByID(data.categories, selectedPackageID);
        if (pkg) {
          setSelectedPackage(pkg);
        } else {
          navigate(``);
        }
      } else if (initialCategoryID) {
        const categoryFromQuery: Category | undefined = getCategoryByID(data.categories, initialCategoryID);
        if (categoryFromQuery) {
          setSelectedCategory(categoryFromQuery);
        } else {
          setSelectedCategory(data.categories[1]);
          navigate(``);
        }
      } else {
        setSelectedCategory(data.categories[1]);
        navigate(``);
      }
    });
  }, []);

  useEffect(() => {
    fetchDiscounts();
  }, []);


  const navigate = useNavigate();

  const scrollToTop = () => {
    // so unnecessarily complicated
    const pg = document.getElementById('page');
    if (pg && pg.parentElement) pg.parentElement.scrollTo(0, 0);
  };

  const handleCategoryClick = (categoryName: string) => {
    const categoryToSelect = categories.find(c => c.name === categoryName);
    if (categoryToSelect == null) return;
    setSelectedCategory(categoryToSelect);
    scrollToTop();
  };

  const handlePackageClick = (packageName: string) => {
    const category = categories.find(c => c.packages.find(pkg => pkg.name === packageName));
    if (category == null) return;
    const packageToSelect = category.packages.find(pkg => pkg.name === packageName);
    if (!packageToSelect) return;
    setSelectedPackage(packageToSelect);
    scrollToTop();
  };

  function GridListButton() {
    return <div className={s.viewToggle}>
      <button
        className={`${s.toggleButton} ${!isListView ? s.active : ''}`}
        title="Grid View"
        onClick={() => setListView(false)} >
        <img src={gridIcon} alt="Grid View" />
      </button>
      <button
        className={`${s.toggleButton} ${isListView ? s.active : ''}`}
        title="List View"
        onClick={() => setListView(true)} >
        <img src={listIcon} alt="List View" />
      </button>
    </div>
  }

  function doMoveUsernameToCard() {
    return selectedPackage && (!selectedPackage.limit_one || !selectedPackage.owned);
  }

  const onClickButton = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    const action = e.currentTarget.name; // buy, sub, user (update just username), update (update username and code)

    console.log("form submitted, user=" + username + ", code=" + coupon);
    fetchPackages(coupon, username)
      .then(data => {
        const isPopupUsername: boolean = action === 'user' || (action === 'update' && !(data.username === validatedUsername));
        if (data.username && data.username.length > 0) {
          Cookies.set('username', data.username, { expires: 7, sameSite: 'lax' });
        } else {
          Cookies.remove('username');
        }
        console.log(`success! action:${action} coupon:${coupon} user:${data.username}`);

        if (action === 'buy' || action === 'sub') {
          console.log(`buy: ${action === 'buy'}`);
          console.log(`sub: ${action === 'sub'}`);
          if (!selectedPackage) {
            setFormPopup("You can't buy nothing", 5000);
            return;
          }
          if (!data.username) {
            setFormPopup("Please enter your username", 5000);
            return;
          }
          checkout(selectedPackage?.id, data.username, coupon, action === 'sub')
            .catch(err => {
              setFormPopup(err, 5000);
            });
        }
      })
      .catch(err => {
        setFormPopup(err, 5000);
      });
  };

  const resetUsername = () => { fetchPackages(coupon, '') };

  const buyForm =
    <div className={s.packageCheckout}>
      {formPopupMessage && formPopupMessage.length > 0 && <div
        className={s.formPopup} >
        <span>{formPopupMessage}</span>
      </div>}
      <div className={s.formRow}>
        <input
          type="text"
          value={username || ""}
          onChange={(e) => {
            if (e.target.value.length > 20) {
              e.target.value = e.target.value.substring(0, 20);
            }
            setUsername(e.target.value);
            setFormPopup('', 0);
          }}
          onKeyDown={(e) => (
            e.key === 'Enter' && fetchPackages(coupon, username)
          )}
          onBlur={() => {
            fetchPackages(coupon, username).catch(err => {
              setFormPopup(err, 5000);
            })
          }}
          placeholder="Username"
          className={s.formInput}
          name="username"
        />
        <img src={`https://mc-heads.net/avatar/${validatedUsername ? validatedUsername : 'crashdummie99'}/32`} />
      </div>
      <form className={s.form}>
        {doMoveUsernameToCard() && selectedPackage && selectedPackage.name !== 'Custom Amount' && <div className={s.formRow}>
          <input
            type="text"
            value={coupon || ""}
            onChange={(e) => {
              if (e.target.value.length > 32) {
                e.target.value = e.target.value.substring(0, 32);
              }
              setCoupon(e.target.value);
              setFormPopup('', 0);
            }}
            placeholder="Coupon or Gift Card"
            className={s.formInput}
            onBlur={() => {
              fetchPackages(coupon, username).catch(err => {
                setFormPopup(err, 5000);
              })
            }}
            name="coupon" />
        </div>}

        {selectedPackage && selectedPackage.name === "Custom Amount" ?
          <>
            <div className={sPack.packageInfoGrid}>
              <span className={sPack.packageName}>{selectedPackage.name}</span>
              <span className={sPack.packagePrice}>
                $<input type="number" min="1" max="1000" step="1" value={quantity} size={5} onChange={event => { setQuantity(Number(event.target.value)) }} />
              </span>
            </div>
          </> :
          (doMoveUsernameToCard() && selectedPackage?.breakdown && selectedPackage.breakdown.map(entry =>
            <div className={sPack.packageInfoGrid}>
              <span className={sPack.packageName}>{entry.key}</span>
              <span className={sPack.packagePrice}>
                <div>
                  <span className={`${sPack.packagePrice} ${entry.value < 0 && sPack.packagePriceDiscount}`}>
                    {entry.value_f}
                    {selectedPackage && selectedPackage.duration && <>/{selectedPackage.duration.toLowerCase()}</>}
                  </span>
                </div>
              </span>
            </div>
          ))}
        {doMoveUsernameToCard() && <div>
          <input
            type="checkbox"
            id="termsCheckbox"
            checked={agreeToTerms}
            onChange={(e) => setAgreeToTerms(e.target.checked)}
          />
          <label htmlFor="termsCheckbox">
            I agree to the <a href="/store/terms" target="_blank" rel="noopener noreferrer">terms of service</a>
          </label>
        </div>}
        {doMoveUsernameToCard() && <div className={`${s.formRow} ${s.submitButtonRow}`}>
          {selectedPackage && (selectedPackage.duration && !selectedPackage.limit_one ? <>
            <button
              type="submit"
              name="sub"
              onClick={onClickButton}
              className={s.submitButton}
              disabled={!validatedUsername || !agreeToTerms}>Subscribe
            </button>
            <button
              type="submit"
              name="buy"
              onClick={onClickButton}
              className={s.submitButton}
              disabled={!validatedUsername || !agreeToTerms}>Buy Once
            </button>
          </> :
            <button
              type="submit"
              name="buy"
              onClick={onClickButton}
              className={s.submitButton}
              disabled={!validatedUsername || !agreeToTerms}>{selectedPackage.cost > 0 ? 'Buy Now' : 'Claim'}
            </button>
          )}
        </div>}
      </form>
    </div>

  const goBack = () => {
    if (!selectedPackage) return;
    handleCategoryClick(selectedPackage.category.name);
  };

  return (<>
    <Page>
      <h1>Webstore</h1>
      <div className={s.categoriesBar}>
        <div className={s.categoriesBarButtons}>
          {categories && categories.map(category => (
            <div className={s.categoriesBarButton} onClick={() => handleCategoryClick(category.name)}>
              <span>{category.name.toUpperCase()}</span>
            </div>
          ))}
        </div>
        {!doMoveUsernameToCard() && buyForm}
      </div>
      <GoalCard goals={goals} />
      <DiscountBanner discounts={discounts} />
      <div className={s.page} id={'page'}>
        <div className={s.group}>
        </div>
        <div className={s.content}>
          {failedToLoad && !categories ? <h3>
            {failedToLoad}
          </h3> : <>
            {selectedCategory && !selectedPackage && <>
              <div className={s.categoryBar}>
                <div><h2 className={s.centerText}>{selectedCategory.name}</h2></div>
                <GridListButton />
              </div>
                <div className={s.centerText}>
                  <Markdown rehypePlugins={[rehypeRaw]} >{selectedCategory.description}</Markdown>
                </div>
            </>}
            {selectedPackage ?
              <PackageViewer
                pkg={selectedPackage}
                currency={currency}
                goBack={goBack}
                buyForm={buyForm}
                resetUsername={resetUsername}
              /> : selectedCategory &&
              <PackageGrid
                packages={selectedCategory.packages}
                isListView={isListView}
                currency={currency}
                onPackageClick={handlePackageClick}
              />
            }
          </>
          }
        </div>
      </div>
    </Page>
    <div className={s.loadingOverlay} style={{ zIndex: loading ? 999 : -999 }}>
      <div className={s.spinner} />
    </div>
  </>
  );
}