import Page from '../../components/page/Page'
import s from "./Rules.module.scss"

export default function Rule2() {
  return (
    <Page>
      <h1>Rule 2 - Don't Cheat</h1>
      <div className={s.content}>
        <span>It's pretty hard to cheat without intending to do so, so this should be an easy one. This rule also outlines the kinds of client mods that are allowed
          on our server.</span>
        <span>As an alternate way of understanding it, rule 2 can be broken down into sub-rules:</span>
        <span><span className={s.subrule}>2.1</span> Don't attempt to hack the server in any way. There are many interpretations of this such as: attempting to seize a staff member's
          account, DDoS or DoSing the server, trying to bungee-spoof the server, etc. All of them aren't allowed.</span>
        <span><span className={s.subrule}>2.2</span> Don't use x-ray. This one gets a special mention due to the existance of x-ray resource packs. Usually resource packs
          present no issue to the server except for in a case like this. X-ray in all forms is not allowed.</span>
        <span><span className={s.subrule}>2.3</span> Don't use a hacked client. This refers to the modified clients that exist loaded with various hacks such as the
          classic kill-aura and flight hacks. They're not allowed.</span>
        <span><span className={s.subrule}>2.4</span> Don't use client mods that give you a significant advantage over other players. Please refer to the next section for
          specific examples.</span>
        <h4>Client Mod Examples</h4>
        <span>It's a bit tricky to understand where the line is on client mods, so we hope these examples of whether certain client mod features are or aren't allowed showcase about where this line is:</span>
        <span><span>Example 1 -</span> World Downloading: <span className={s.subrule}>Not Allowed</span></span>
        <span><span>Example 2 -</span> Schematic Pasting or Printing: <span className={s.subrule}>Not Allowed</span></span>
        <span><span>Example 3 -</span> Looping Macros: <span className={s.subrule}>Not Allowed</span></span>
        <span><span>Example 4 -</span> Locating Players or Mobs: <span className={s.subrule}>Not Allowed</span></span>
        <span><span>Example 5 -</span> Automating In-Game Activities: <span className={s.subrule}>Not Allowed</span></span>
        <span><span>Example 6 -</span> Cave Layers or Entity Finders: <span className={s.subrule}>Not Allowed</span></span>
        <span><span>Example 7 -</span> Command Macros: <span className={s.allowed}>Allowed</span></span>
        <span><span>Example 8 -</span> Minimap (without cave layers or entity finders): <span className={s.allowed}>Allowed</span></span>
        <span><span>Example 9 -</span> Zooming In: <span className={s.allowed}>Allowed</span></span>
        <span><span>Example 10 -</span> Extra Animations: <span className={s.allowed}>Allowed</span></span>
        <span><span>Example 11 -</span> Full Bright: <span className={s.allowed}>Allowed</span></span>
      </div>
    </Page>
  )
}
