import Page from '../../components/page/Page'
import s from "./Info.module.scss"

export default function Elytra() {
  return (
    <Page>
      <h1>Your First Elytra</h1>
      <div className={s.content}>
        <span>On A Towny Server, there is a special way to get your first elytra.</span>
        <span>To get an elytra in this way, you must do the following in order:</span>
        <ul>
          <li>Kill the Ender Dragon, doing at least 1/3rd of the damage yourself. You will receive 4 end crystals to respawn the dragon when you first travel through an end portal.</li>
          <li>Find an End City which has not been visited within the last hour and without teleporting there.</li>
        </ul>
        <span>After you get your first elytra, either as specified above or from an end ship as normal, you cannot use this method. If you want more elytra, you need to find them in unlooted End Cities or buy them from another player.</span>
        <i>If you already had an elytra before September 30th 2022, you can't get an elytra in this way.</i>
      </div>
    </Page>
  )
}
