import Package from "../Interfaces/Package"
import Card from "./Card"
import s from "./Package.module.scss"

import Markdown from 'react-markdown'
import rehypeRaw from 'rehype-raw'

interface Props {
  packages:Package[];
  isListView:boolean;
  currency: String|null;
  onPackageClick: (name:string) => void;
}

/**
 * For displaying a grid of packages
 * 
 * @param props 
 * @returns 
 */
export default function PackageGrid (prop:Props) {  
    // Explicitly typing the rows array
    const rows: Package[][] = prop.packages.reduce((acc: Package[][], pkg: Package, index: number) => {
      const rowNum = Math.floor(index / 3); // Calculate the current row number
      if (!acc[rowNum]) acc[rowNum] = []; // Create a new row if it doesn't exist
      acc[rowNum].push(pkg); // Push the package into the correct row
      return acc;
    }, []);
  
    const pkgRender = (pkg: Package) => (
      <>
        <div>
          <Card pkg={pkg} isListView={prop.isListView} packageDetails={false} currency={prop.currency} onClick={prop.onPackageClick} />
          {prop.isListView && <Markdown rehypePlugins={[rehypeRaw]} >{pkg.features && pkg.description}</Markdown>}
        </div>
        {prop.isListView && <div>
          {pkg.features && pkg.index > 0 && <h4>Everything from the ranks above and:</h4>}
          <Markdown rehypePlugins={[rehypeRaw]}>{pkg.features ? pkg.features : pkg.description}</Markdown>
        </div>}
      </>
    );
  
    if (prop.isListView) {
      // For list view, simply map over all packages
      return (
        <div className={s.packagesList}>
          {prop.packages.map(pkgRender)}
        </div>
      );
    } else {
      // Render each row for the grid view
      return (
        <>
          {rows.map((row, index) => (
            <div key={index} className={`${s.packagesGrid} ${row.length == 2 && s.packagesGridEven}`}>
              {row.length < 3 && <div/>}
              {row.map(pkgRender)}
            </div>
          ))}
        </>
      );
    }
  };