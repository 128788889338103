import Page from '../../components/page/Page'
import s from "./Rules.module.scss"

export default function Rule5() {
  return (
    <Page>
      <h1>Rule 5 - No AFK Farming</h1>
      <div className={s.content}>
        <span>We know, we know. You big-time grinders want to set up your farms to play the Minecraft Idle Game. However, ATS was made to be a community server first and foremost. We know that you don't like this rule and that's kinda why we have it.</span>
        <span>As an alternate way of understanding it, rule 5 can be broken down into sub-rules:</span>
        <span><span className={s.subrule}>5.1</span> Don't sit next to farms while you are not actively playing the game.</span>
        <span><span className={s.subrule}>5.2</span> Don't build or use a chunk loader.</span>
        <span><span className={s.subrule}>5.3</span> Don't intentionally bypass the 20-minute AFK kick. If you want to AFK without being kicked, go to spawn or the market.</span>
        <h4>Examples of what is or isn't allowed:</h4>
        <span><span>Example 1 -</span> Sit next to auto-farms while engaging in conversation in chat: <span className={s.allowed}>Allowed</span></span>
        <span><span>Example 2 -</span> Run around in a circle next to your auto-farms: <span className={s.allowed}>Allowed</span></span>
        <span><span>Example 3 -</span> Go AFK next to a farm and then rejoin after you get kicked only to go AFK again: <span className={s.subrule}>Not Allowed</span></span>
        <span><span>Example 4 -</span> Tab into the game every few minutes and run around to avoid getting kicked: <span className={s.subrule}>Not Allowed</span></span>
        <span><span>Example 5 -</span> Sit next to a farm and tab out to watch YouTube: <span className={s.subrule}>Not Allowed</span></span>
        <span><span>Example 6 -</span> Set up an auto clicker or water loop and then go AFK: <span className={s.subrule}>Not Allowed</span></span>
        <span><span>Example 7 -</span> Remain motionless in-game while you stare blankly into the void waiting for that sweet, sweet iron to build up so you can sell it to feel an ounce of happiness when you see that little green number get bigger... <span className={s.notApplicable}>Maybe go touch grass?</span></span>
      </div>
    </Page>
  )
}
