import Page from '../../components/page/Page'
import s from "./Rules.module.scss"

export default function Rule4() {
  return (
    <Page>
      <h1>Rule 4 - Keep it PG-13</h1>
      <div className={s.content}>
        <span>This rule doesn't mean what it says exactly. The server isn't strictly PG-13, it's just an easy way to summarise most of our expectations in an
          easy-to-understand way. We do, however, have some guidelines as to what is and what isn't okay to make references to on our server.</span>
        <span><span>Example 1 -</span> Talking about hard drugs such as cocaine, meth, or heroin in any way: <span className={s.subrule}>Not OK</span></span>
        <span><span>Example 2 -</span> Making a 420 joke:  <span className={s.allowed}>OK</span></span>
        <span><span>Example 3 -</span> Selling "fake drugs" to players: <span className={s.subrule}>Not OK</span></span>
        <span><span>Example 4 -</span> Role-playing being drunk while messing with Brewery brews: <span className={s.allowed}>OK</span></span>
        <span><span>Example 5 -</span> Opening up about your history with substance abuse: <span className={s.subrule}>Not OK</span></span>
        <span><span>Example 6 -</span> Joking about punting a baby: <span className={s.allowed}>OK</span></span>
        <span><span>Example 7 -</span> Telling a story about how your underage friend was hit by their parents: <span className={s.subrule}>Not OK</span></span>
        <span><span>Example 8 -</span> Making a flirtatious joke to a consenting player: <span className={s.allowed}>OK</span></span>
        <span><span>Example 9 -</span> Engaging in erotic role-play with a player: <span className={s.subrule}>Not OK</span></span>
      </div>
    </Page>
  )
}
