import Page from '../../components/page/Page'
import { useNavigate } from "react-router-dom"
import s from "./Info.module.scss"
import disposal from "../../images/disposalsign.png"
import godshop from "../../images/godshop.jpg"

export default function Ranks() {
  const nav = useNavigate()

  return (
    <Page>
      <h1>In-Game Ranks</h1>
      <div className={s.content}>
        <span>In-game ranks are permanent command-permission groups that you can buy with in-game currency. You can view and purchase them in-game using: <span className={s.command}>/ranks</span></span>
        <span>Before you can purchase these ranks, you need to claim the free rank from our <span onClick={() => nav("/store")} className="link">webstore</span>.</span>
        <span>All ranks require the one that comes before it to be purchased before it can be purchased.</span>
        <span>All ranks except the free rank give:</span>
        <div className={s.group}>
          <span>+10 Bonus Claims</span>
          <span>+1 Bonus Home</span>
          <span>+1 Maximum Jobs</span>
          <span>+9 Backpack Slots</span>
        </div>

        <h3 className={s.rankTitle} style={{ "color": "rgb(255, 255, 255)" }}>Free Rank</h3>
        <span>$250 of in-game currency</span>
        <span>The white player prefix</span>
        <span>The ability to talk when the chat is toggled off by a moderator</span>
        <span>The ability to purchase the in-game <span className={s.command}>/ranks</span></span>
        <span>The ability to post links in chat</span>
        <span>Get added to the VPN whitelist so you can use a VPN on our server</span>

        <h3 className={s.rankTitle} style={{ "color": "rgb(80, 255, 67)" }}>Noble</h3>
        <span>Costs: $1,000</span>
        <span><span className={s.command}>/craft</span>- Opens a crafting table anywhere without needing a crafting table block.</span>
        <span><span className={s.command}>/theend</span>- Teleport to the end from anywhere.</span>

        <h3 className={s.rankTitle} style={{ "color": "rgb(80, 225, 203)" }}>Baron</h3>
        <span>Costs: $3,160</span>
        <span><span className={s.command}>/rest</span>- Resets your insomnia stat causing phantoms to stop spawning and attacking you at night.</span>
        <span><span className={s.command}>/trash</span>- Opens a chest inventory that deletes anything you put in it when you close it.</span>
        <span>The ability to create disposal signs by writing the following on the first line of a sign: <span className={s.command}>[Disposal]</span></span>
        <img alt="" className={s.img} src={disposal} loading="lazy" />

        <h3 className={s.rankTitle} style={{ "color": "rgb(240, 75, 203)" }}>Knight</h3>
        <span>Costs: $10,000</span>
        <span><span className={s.command}>/echest</span>- Opens an ender chest anywhere without needing an ender chest block.</span>
        <span><span className={s.command}>/sober</span>- Removes any negative effects and drunkenness caused by brewery drinks.</span>

        <h3 className={s.rankTitle} style={{ "color": "rgb(60, 68, 169)" }}>Viscount</h3>
        <span>Costs: $31,600</span>
        <span><span className={s.command}>/ascend</span>- Teleport through any blocks above you to the first available empty space.</span>
        <span><span className={s.command}>/feed</span>- Sets your hunger to full and gives you full saturation. 60 second cooldown.</span>
        <span><span className={s.command}>/nv</span>- Toggles permanent night vision.</span>
        <span><span className={s.command}>/blocks</span>- Turns any items in your inventory that can be crafted up into blocks into their block forms.</span>

        <h3 className={s.rankTitle} style={{ "color": "rgb(240, 75, 67)" }}>Lord</h3>
        <span>Costs: $100,000</span>
        <span style={{ "color": "rgb(240, 75, 67)" }}>Instant Teleportation!</span>
        <span><span className={s.command}>/heal</span>- Sets your health and hunger to full and clears all effects from you. 60 second cooldown.</span>
        <span><span className={s.command}>/anvil</span>- Opens an anvil anywhere without needing an anvil block.</span>
        <span><span className={s.command}>/cartographytable</span>- Opens a cartography table anywhere without needing a cartography table block.</span>
        <span><span className={s.command}>/grindstone</span>- Opens a grindstone anywhere without needing a grindstone block.</span>
        <span><span className={s.command}>/loom</span>- Opens a loom anywhere without needing a loom block.</span>
        <span><span className={s.command}>/smithingtable</span>- Opens a smithing table anywhere without needing a smithing table block.</span>
        <span><span className={s.command}>/stonecutter</span>- Opens a stonecutter anywhere without needing a stonecutter block.</span>

        <h3 className={s.rankTitle} style={{ "color": "rgb(160, 0, 135)" }}>Emperor</h3>
        <span>Costs: $316,000</span>
        <span><span className={s.command}>/arrow</span>- Shoots an arrow where you're looking. 15 second cooldown.</span>
        <span><span className={s.command}>/egg</span>- Shoots an egg where you're looking. 20 second cooldown.</span>
        <span><span className={s.command}>/snowball</span>- Shoots a snowball where you're looking.</span>
        <span><span className={s.command}>/trident</span>- Shoots a trident where you're looking. 25 second cooldown.</span>
        <span><span className={s.command}>/fix</span>- Sets the durability of any item you're holding to its maximum, fully repairing it. 150 second cooldown.</span>
        <span><span className={s.command}>/smelt</span>- Instantly smelts the item in your hand. Does not give any rewards like Jobs XP or money.</span>

        <h3 className={s.rankTitle} style={{ "color": "rgb(239, 150, 0)" }}>GOD</h3>
        <span>Costs: $1,000,000</span>
        <span><span className={s.command}>/fly</span>- Enables creative-style free-flight.</span>
        <span><span className={s.command}>/smite</span>- Calls down a lightning strike where you're looking.</span>
        <span><span className={s.command}>/powertool</span>- Assign a command to an item that executes when you left-click with it.</span>
        <span><span className={s.command}>/powertooltoggle</span>- Enables or disables command execution on all powertool items you've created.</span>
        <span><span className={s.command}>/note</span>- Spends $100 of in-game currency to sign a $100 bank note. Anyone who right clicks while holding a bank note gets its value added to their balance.</span>
        <span><span className={s.command}>/notestack</span>- Executes /note 64 times, creating a stack of bank notes.</span>
        <span><span className={s.command}>/godshop</span>- Opens a special shop only available to god-ranked players including Elytra and Wishes.</span>
        <img alt="" className={s.img} src={godshop} loading="lazy" />
      </div>
    </Page>
  )
}
