import Page from '../../components/page/Page'
import s from "./Rules.module.scss"

export default function Rule9() {
  return (
    <Page>
      <h1>Rule 9 - Don't Exploit</h1>
      <div className={s.content}>
        <span>As an alternate way of understanding it, rule 9 can be broken down into sub-rules:</span>
        <span><span className={s.subrule}>9.1</span> Don't abuse bugs, glitches, or exploits and alert a staff member if you discover one.</span>
        <span><span className={s.subrule}>9.2</span> Don't abuse obvious mistakes in /shop pricing and alert a staff member if you discover one.</span>
        <h4>Things that count as exploits:</h4>
        <span>- Making items with enormous amounts of data that crash people's games</span>
        <span>- Pushing a block into your head so you can see through the ground</span>
        <span>- The lapis-diamond glitch</span>
        <span>- The clay-diamond glitch</span>
        <span>- Block-glitching</span>
        <span>- TNT Duping</span>
      </div>
    </Page>
  )
}
