import Page from '../../components/page/Page'
import s from "./Rules.module.scss"

export default function Rule7() {
  return (
    <Page>
      <h1>Rule 7 - No Imposters</h1>
      <div className={s.content}>
        <span>As an alternate way of understanding it, rule 7 can be broken down into sub-rules:</span>
        <span><span className={s.subrule}>7.1</span> Don't impersonate other players, especially staff members, for example by changing your nickname to look like theirs.</span>
        <span><span className={s.subrule}>7.2</span> Don't use someone else's creative works on the server, such as their builds, to make in-game currency without their permission.</span>
      </div>
    </Page>
  )
}
