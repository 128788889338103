import Page from '../../components/page/Page'
import s from "./Rules.module.scss"

export default function Rule1() {
  return (
    <Page>
      <h1>Rule 1 - Be Nice</h1>
      <div className={s.content}>
        <span>This is the first and most important rule of the server. It embodies a very simple premise: If you don't want to be nice, don't play here.</span>
        <span>As an alternate way of understanding it, rule 1 can be broken down into sub-rules:</span>
        <span><span className={s.subrule}>1.1</span> Don't insult other players.</span>
        <span><span className={s.subrule}>1.2</span> Don't bully anyone in any way, for example intentionally trying to upset someone.</span>
        <span><span className={s.subrule}>1.3</span> Don't harass anyone, for example refuse to leave them alone or spam-message them.</span>
        <span><span className={s.subrule}>1.4</span> Don't scam players out of their items, money, builds, town, nation, etc.</span>
        <span><span className={s.subrule}>1.5</span> Don't grief players, as in destroying something they've built without their permission.</span>
        <span><span className={s.subrule}>1.6</span> Don't steal blocks, items, builds, etc, from players.</span>
        <span><span className={s.subrule}>1.7</span> Don't socially manipulate others, for example with intentional peer pressure, blackmail, or lying.</span>
        <span><span className={s.subrule}>1.8</span> Don't attack other players without their permission.</span>
        <span><span className={s.subrule}>1.9</span> Don't use offensive language, such as the 'R' or 'N' words.</span>
        <span><span className={s.subrule}>1.10</span> Don't use exessively vulgar language, for example saying the 'F' word multiple times.</span>
      </div>
    </Page>
  )
}
