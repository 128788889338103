import React, { useState } from 'react';
import Page from "../../components/page/Page"

function schedule(run: () => void, millis: number) {
    (async () => {
        const timeout = setTimeout(run, millis)
        //return () => clearTimeout(timeout)
    })();
}

export default function ThankYou() {
    const [countdown, setCountdown] = useState<number>(3);

    if (countdown == 3) {
        schedule(() => setCountdown(2), 1000);
        schedule(() => setCountdown(1), 2000);
        schedule(() => setCountdown(0), 3000);
        schedule(() => window.location.href = 'https://atownyserver.com/store', 3200);
    }

    return <Page>
        <h2>Thank you! :)</h2>
        <h4>Redirecting{countdown > 0 && ` in ${countdown} seconds`}...</h4>
    </Page>
}