import Page from "../../components/page/Page"
import s from "./Download.module.scss"
import React, { useState, ReactNode } from 'react';

type Props = {
    children: ReactNode
}

function Link({ children }: Props) {
    return (
        <a rel="license" href="http://creativecommons.org/licenses/by-nc/4.0/" target="_blank">
            {children}
        </a>
    )
}

export default function Download() {
    const [selectedFile, setSelectedFile] = useState('Towny Classic');
    const [agree, setAgree] = useState(false);

    type FileData = {
        [key: string]: {
            md5: string;
            sha256: string;
            url: string;
            size: string;
        };
    };

    const fileData: FileData = {
        "Towny Classic": {
            "md5": "42b304b65cdea1a46306c502161b483a",
            "sha256": "8ed1d48f38331535c395605abf157d65d9b800051f47734f37f96e85540b002d",
            "url": "https://drive.usercontent.google.com/download?id=1O4EDpB1YJBjrjkOcE3YCUB60Q2ukSlWB&export=download",
            "size": "29.8GB"
        },
        "Towny Azalea": {
            "md5": "4e22a3f0c9f6f6b0f8453dc93558261e",
            "sha256": "31e9a20f5fd82396f6e39eeaaa2a4a0af2b2e4682a0b0568a0b0823c02828ac7",
            "url": "https://drive.usercontent.google.com/download?id=1CGQbWiFT4fHTSiV6py8q7x99w7c-ZSPo&export=download",
            "size": "37.0GB"
        },
        // other files
    };

    const handleFileChange = (event: { target: { value: React.SetStateAction<string>; }; }) => {
        setSelectedFile(event.target.value);
    };

    const handleAgreementChange = (event: { target: { checked: boolean | ((prevState: boolean) => boolean); }; }) => {
        setAgree(event.target.checked);
    };

    const downloadFile = () => {
        window.location.href = fileData[selectedFile]?.url;
    };

    return (
        <Page>
            <h1>A Towny Server Downloads</h1>
            <div className={s.content}>
                <div className={s.content2}>
                    <select className={s.dropdown} onChange={handleFileChange} value={selectedFile}>
                        {Object.keys(fileData).map(file => (
                            <option key={file} value={file}>{file}</option>
                        ))}
                    </select>
                    <div className={s.agree_section}>
                        <input type="checkbox" id="agreeCheckbox" checked={agree} onChange={handleAgreementChange} />
                        <label htmlFor="agreeCheckbox">I have read, understand, and agree to <Link>the license</Link>.</label>
                    </div>
                    <button id={s.downloadButton} disabled={!agree} onClick={downloadFile}>Download{selectedFile && ` - ${fileData[selectedFile]?.size}`}</button>
                    <div className={s.hashes_container}>
                        <h3>Hashes</h3>
                        <p className={s.hash_entry}><span className={s.hash_label}>MD5:</span> <span>{fileData[selectedFile]?.md5 || 'Not available'}</span></p>
                        <p className={s.hash_entry}><span className={s.hash_label}>SHA256:</span> <span>{fileData[selectedFile]?.sha256 || 'Not available'}</span></p>
                    </div>
                    <p>
                        <Link>
                            <img alt="Creative Commons License" style={{ borderWidth: 0 }} src="https://i.creativecommons.org/l/by-nc/4.0/88x31.png" />
                        </Link><br />
                        This world was created by, and is the property of, A Towny Server. This work is licensed under a <Link>Creative Commons Attribution-NonCommercial 4.0 International License</Link>.
                    </p>
                </div>
                <h4>Installation Instructions (Singleplayer)</h4>
                <ul>
                    <li>Download the file</li>
                    <li>Unzip</li>
                    <li><a href="https://minecraft.fandom.com/wiki/.minecraft" target="_blank">Navigate to your .minecraft directory</a></li>
                    <li>Copy the unzipped world folder into the saves directory, so that the directory .minecraft\saves\classic has the advancements folder, data folder, datapacks folder, etc. (Not a second classic directory)</li>
                </ul>
            </div>
        </Page>
    );
}
