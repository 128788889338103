import Page from '../../components/page/Page'
import { useNavigate } from "react-router-dom"
import s from "./Info.module.scss"

export default function Commands() {
  const nav = useNavigate()

  return (
    <Page>
      <h1>Command List</h1>
      <div className={s.content}>
        <span><span className={s.command}>/abandoned</span>- Tells you if the nearby area is in use by another player or not. Taking anything from a non-abandoned area is against the rules.</span>

        <span><span className={s.command}>/agreements</span>- List your agreements with other players. See /propose</span>

        <div className={s.group}>
          <span><span className={s.command}>/afk</span>- Toggles your AFK status to let other players know if you're away or not.</span>
          <span>Aliases: <span className={s.command}>/away</span></span>
        </div>

        <span><span className={s.command}>/apply</span>- Provides a link to the staff application form.</span>

        <div className={s.group}>
          <span><span className={s.command}>/areashop</span>- Master command for managing your rented shop in the market.</span>
          <span>Aliases: <span className={s.command}>/as</span></span>
        </div>

        <div className={s.group}>
          <span><span className={s.command}>/auctionhouse</span>- Opens the Auction House where players can list items for sale to other players.</span>
          <span>Aliases: <span className={s.command}>/ah</span></span>
        </div>

        <div className={s.group}>
          <span><span className={s.command}>/back</span>- Teleports to where you were before your latest teleport or your latest death point, whichever is most recent.</span>
          <span>Aliases: <span className={s.command}>/return</span></span>
        </div>

        <div className={s.group}>
          <span><span className={s.command}>/balance</span>- Displays your balance of in-game currency.</span>
          <span>Aliases: <span className={s.command}>/bal</span></span>
        </div>

        <div className={s.group}>
          <span><span className={s.command}>/balancetop</span>- Displays player balances in order of who has the most money.</span>
          <span>Aliases: <span className={s.command}>/baltop</span></span>
        </div>

        <span><span className={s.command}>/boogy</span>- A chat emote that says you start boogying.</span>

        <span><span className={s.command}>/claimplot</span>- Claims the current plot you're standing as just yours in inside of your town.</span>

        <span><span className={s.command}>/claimrank[rank]</span>- Claims a rank that you're missing on one of the ATS network servers. Requires you to have had the rank on at least one of our servers. There is no space between "claimrank" and the rank's name.
        </span>

        <div className={s.group}>
          <span><span className={s.command}>/colors</span>- Shows you all the colour codes.</span>
          <span>Aliases: <span className={s.command}>/colours</span></span>
        </div>

        <div className={s.group}>
          <span><span className={s.command}>/coords</span>- Shows you your current coordiantes and world with an option to copy your coordinates.</span>
          <span>Aliases: <span className={s.command}>/getpos</span>
            <span className={s.command}>/position</span>
          </span>
        </div>

        <div className={s.group}>
          <span><span className={s.command}>/cosmetics</span>- Opens the cosmetics menu.</span>
          <span>Aliases: <span className={s.command}>/c</span></span>
        </div>

        <span><span className={s.command}>/cough</span>- A chat emote that says you cough.</span>

        <span><span className={s.command}>/countdown [seconds/stop]</span>- Starts or stops an in-game timer on your action bar.</span>

        <span><span className={s.command}>/dab</span>- A chat emote that says you dab.</span>

        <span><span className={s.command}>/dance</span>- A chat emote that says you start dancing.</span>

        <span><span className={s.command}>/delhome [home]</span>- Deletes one of your set homes.</span>

        <span><span className={s.command}>/deposit [amount]</span>- Puts an amount of money into your town's bank.</span>

        <span><span className={s.command}>/discord</span>- Displays the link to the ATS Discord server.</span>

        <div className={s.group}>
          <span><span className={s.command}>/donate</span>- Displays a link to the ATS webstore.</span>
          <span>Aliases: <span className={s.command}>/store</span></span>
        </div>

        <span><span className={s.command}>/dry</span>- A chat emote that says you're drying.</span>

        <div className={s.group}>
          <span><span className={s.command}>/echo [message]</span>- Displays a message in chat that only you can see.</span>
          <span>Aliases: <span className={s.command}>/pong</span></span>
        </div>

        <span><span className={s.command}>/economy</span>- Displays the economy help menu.</span>

        <span><span className={s.command}>/facepalm</span>- A chat emote that says you facepalm.</span>

        <span><span className={s.command}>/faq</span>- Opens the frequently asked questions list.</span>

        <span><span className={s.command}>/friendplot</span>- Gives players on your towny friends list access to your personal plot in your town.</span>

        <div className={s.group}>
          <span><span className={s.command}>/general</span>- Switches you to general chat. Follow the command with a message to send a message in the channel without switching to it.</span>
          <span>Aliases: <span className={s.command}>/g</span></span>
        </div>

        <span><span className={s.command}>/getping</span>- Displays your latency to the server in milliseconds.</span>

        <span><span className={s.command}>/group</span>- The group-chat master command.</span>

        <span><span className={s.command}>/head [player]</span>- Get a player's head. Costs $250.</span>

        <span><span className={s.command}>/heads</span>- Provides a list of player heads.</span>

        <div className={s.group}>
          <span><span className={s.command}>/helpop [message]</span>- Send a message that only staff members can see.</span>
          <span>Aliases: <span className={s.command}>/helpme</span></span>
        </div>

        <span><span className={s.command}>/history</span>- View your punishment history.</span>

        <span><span className={s.command}>/home [home]</span>- Teleport to a home you have set. If you only have one home, typing the command without any arguments will teleport you to that home.</span>

        <span><span className={s.command}>/hub</span>- Join the queue to switch to the hub server.</span>

        <span><span className={s.command}>/hug [player]</span>- Hug a player. Makes everybody feel better.</span>

        <div className={s.group}>
          <span><span className={s.command}>/ignore [player]</span>- Block a player from sending you private messages.</span>
        </div>

        <span><span className={s.command}>/ip</span>- Displays the server IP.</span>

        <div className={s.group}>
          <span><span className={s.command}>/info</span>- Opens the server's central help menu. It's by far the best command in the game! Tell all your friends.</span>
          <span>Aliases: <span className={s.command}>/gui</span>, <span className={s.command}>/help</span>, <span className={s.command}>/information</span>, <span className={s.command}>/menu</span></span>
        </div>

        <span><span className={s.command}>/jobs</span>- The <span className="link" onClick={() => nav("/info/jobs")}>Jobs</span> master command.</span>

        <span><span className={s.command}>/laugh</span>- A chat emote that says you start laughing.</span>

        <span><span className={s.command}>/leavequeue</span>- Removes you from the waiting queue for joining a server.</span>

        <span><span className={s.command}>/linkaccount</span>- Displays instructions to set up accessing your java account when you log in using bedrock edition.</span>

        <div className={s.group}>
          <span><span className={s.command}>/list</span>- Lists all players online on the network server you're currently connected to.</span>
          <span>Aliases: <span className={s.command}>/online</span></span>
        </div>

        <span><span className={s.command}>/lock</span>- Lock a container or door you're looking at.</span>

        <span><span className={s.command}>/mail</span>- Master command for managing in-game mail.</span>

        <span><span className={s.command}>/main</span>- Join the queue to switch to the main towny server.</span>

        <span><span className={s.command}>/map</span>- Displays the towny map.</span>

        <span><span className={s.command}>/market</span>- Opens the market menu.</span>

        <div className={s.group}>
          <span><span className={s.command}>/marry</span>- The marriage master command.</span>
          <span>Aliases: <span className={s.command}>/m</span></span>
        </div>

        <span><span className={s.command}>/mayor</span>- Displays a link to the town handbook.</span>

        <div className={s.group}>
          <span><span className={s.command}>/message [player] [message]</span>- Sends a private message to a player.</span>
          <span>Aliases: <span className={s.command}>/msg</span>, <span className={s.command}>/tell</span>, <span className={s.command}>/w</span>, <span className={s.command}>/whisper</span></span>
        </div>

        <span><span className={s.command}>/misc</span>- Opens the miscelleneous commands menu.</span>

        <span><span className={s.command}>/motd</span>- Displays the login message.</span>

        <div className={s.group}>
          <span><span className={s.command}>/music</span>- Opens the music menu with a small variety of noteblock songs and the option to turn off the music in spawn.</span>
          <span>Aliases: <span className={s.command}>/jukebox</span></span>
        </div>

        <span><span className={s.command}>/muteredstone</span>- Toggle the sounds of pistons, droppers, and dispensers.</span>

        <div className={s.group}>
          <span><span className={s.command}>/nation</span>- The <a className="link" href="https://github.com/TownyAdvanced/Towny/wiki/Towny-Commands">Towny</a> nation master command.</span>
          <span>Aliases: <span className={s.command}>/n</span></span>
        </div>

        <div className={s.group}>
          <span><span className={s.command}>/nchat</span>- Switches you to nation chat chat. Follow the command with a message to send a message in the channel without switching to it.</span>
          <span>Aliases: <span className={s.command}>/nc</span></span>
        </div>

        <span><span className={s.command}>/pay [player] [amount]</span>- Send a player an amount of in-game currency deducted from your balance.</span>

        <div className={s.group}>
          <span><span className={s.command}>/playtime</span>- Displays the amount of time you have played on the server.</span>
          <span>Aliases: <span className={s.command}>/pt</span></span>
        </div>

        <span><span className={s.command}>/plot</span>- The <a className="link" href="https://github.com/TownyAdvanced/Towny/wiki/Towny-Commands">Towny</a> plots master command.</span>

        <span><span className={s.command}>/policies</span>- Provides a link to the policies document.</span>

        <span><span className={s.command}>/potm</span>- The Player of the Month master command.</span>

        <span><span className={s.command}>/propose [player] [description]</span>- Create a proposal for an agreement between you and another player. This can be used to create contracts that are indisputable and permanent.</span>

        <span><span className={s.command}>/ranks</span>- Displays the <span className="link" onClick={() => nav("/info/ranks")}>In-Game Ranks</span> menu.</span>

        <span><span className={s.command}>/realname [nickname]</span>- Get a player's account name from their nickname.</span>

        <div className={s.group}>
          <span><span className={s.command}>/reply [message]</span>- Sends a message to the last person who messaged you.</span>
          <span>Aliases: <span className={s.command}>/r</span>, <span className={s.command}>/respond</span></span>
        </div>

        <span><span className={s.command}>/replytoggle</span>- Changes the functionality of the reply command to send a message to the last person you messaged.</span>

        <div className={s.group}>
          <span><span className={s.command}>/resident [player]</span>- Displays a player's <a className="link" href="https://github.com/TownyAdvanced/Towny/wiki/Towny-Commands">Towny</a> resident information.</span>
          <span>Aliases: <span className={s.command}>/p</span>, <span className={s.command}>/player</span>, <span className={s.command}>/res</span></span>
        </div>

        <span><span className={s.command}>/resource</span>- Opens the resource worlds menu.</span>

        <span><span className={s.command}>/rules</span>- Displays the rules.</span>

        <span><span className={s.command}>/seen [player]</span>- See when a player was last online.</span>

        <span><span className={s.command}>/sell</span>- Sells items in your inventory.</span>

        <span><span className={s.command}>/servers</span>- Opens the servers menu.</span>

        <span><span className={s.command}>/sethome [name]</span>- Set a home and gives it the specified name.</span>

        <span><span className={s.command}>/shop</span>- Opens the shop menu.</span>

        <span><span className={s.command}>/sigh</span>- A chat emote that says you sigh.</span>

        <span><span className={s.command}>/snore</span>- A chat emote that says you snore.</span>

        <span><span className={s.command}>/sob</span>- A chat emote that says you start sobbing.</span>

        <span><span className={s.command}>/sort</span>- Organises a chest you're looking at.</span>

        <span><span className={s.command}>/spawn</span>- Teleports you to the server spawn.</span>

        <div className={s.group}>
          <span><span className={s.command}>/store</span>- Provides a link to the webstore.</span>
          <span>Aliases: <span className={s.command}>/donate</span></span>
        </div>

        <div className={s.group}>
          <span><span className={s.command}>/swarp</span>- The shop warps master command.</span>
          <span>Aliases: <span className={s.command}>/sw</span></span>
        </div>

        <div className={s.group}>
          <span><span className={s.command}>/tchat</span>- Switches you to town chat. Follow the command with a message to send a message in the channel without switching to it.</span>
          <span>Aliases: <span className={s.command}>/tc</span></span>
        </div>

        <div className={s.group}>
          <span><span className={s.command}>/town</span>- The <a className="link" href="https://github.com/TownyAdvanced/Towny/wiki/Towny-Commands">Towny</a> town master command.</span>
          <span>Aliases: <span className={s.command}>/t</span></span>
        </div>

        <span><span className={s.command}>/towny</span>- The towny master command.</span>

        <div className={s.group}>
          <span><span className={s.command}>/townybasics</span>- Opens the towny basics help menu.</span>
          <span>Aliases: <span className={s.command}>/tb</span></span>
        </div>

        <div className={s.group}>
          <span><span className={s.command}>/townyhelp</span>- Opens the towny help menu.</span>
          <span>Aliases: <span className={s.command}>/th</span></span>
        </div>

        <span><span className={s.command}>/tpa [player]</span>- Request to teleport to a player.</span>

        <div className={s.group}>
          <span><span className={s.command}>/tpahere [player]</span>- Request a player to teleport to you.</span>
        </div>

        <div className={s.group}>
          <span><span className={s.command}>/tpno</span>- Deny a teleport request.</span>
          <span>Aliases: <span className={s.command}>/tpdeny</span></span>
        </div>

        <div className={s.group}>
          <span><span className={s.command}>/tpyes</span>- Accept a teleport request.</span>
          <span>Aliases: <span className={s.command}>/tpaccept</span></span>
        </div>

        <span><span className={s.command}>/tpp</span>- The TPPets master command.</span>

        <span><span className={s.command}>/trade [player]</span>- Invite a nearby player to trade with you.</span>

        <span><span className={s.command}>/trust [player]</span>- Give a player access to your locked blocks.</span>

        <span><span className={s.command}>/unlock</span>- Unlocks a locked chest or door.</span>

        <span><span className={s.command}>/unrent</span>- Unrents your shop in the market and gives you a pro-rata refund on the rent you paid.</span>

        <span><span className={s.command}>/vote</span>- Vote for the server.</span>

        <span><span className={s.command}>/warnings</span>- Displays your warnings.</span>

        <span><span className={s.command}>/warp [warp]</span>- Teleports you to a warp.</span>

        <span><span className={s.command}>/warps</span>- Displays all warps.</span>

        <span><span className={s.command}>/withdraw [amount]</span>- Takes an amount of money out of your town's bank.</span>

        <span><span className={s.command}>/worth [item]</span>- View the worth of an item, accounting for dynamic pricing.</span>
      </div>
    </Page>
  )
}
