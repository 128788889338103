import Page from '../../components/page/Page'
import s from "./Rules.module.scss"

export default function Rule6() {
  return (
    <Page>
      <h1>Rule 6 - Respect the Chat</h1>
      <div className={s.content}>
        <span>As an alternate way of understanding it, rule 6 can be broken down into sub-rules:</span>
        <span><span className={s.subrule}>6.1</span> Don't spam the chat by repeating the same or similar messages multiple times.</span>
        <span><span className={s.subrule}>6.2</span> Don't flood the chat with lots of messages in quick succession.</span>
        <span><span className={s.subrule}>6.3</span> Don't advertise non-ATS related things in the server chat without permission from an admin such as your social media, other servers, your YouTube channel, etc.</span>
        <span><span className={s.subrule}>6.4</span> Don't speak languages other than English in global chat. We need to be able to read all messages in order to effectively moderate them. You are welcome to speak any language in PMs or private chats.</span>
        <span><span className={s.subrule}>6.5</span> Don't beg for items or money. Asking once for something is fine but don't guilt-trip, repeat it multiple times, or otherwise be excessively annoying about it.</span>
      </div>
    </Page>
  )
}
