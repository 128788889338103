import Page from '../../components/page/Page'
import { useNavigate } from "react-router-dom"
import s from "./Info.module.scss"
import balance from "../../images/balance.png"
import jobsbrowse from "../../images/jobsbrowse.jpg"
import jobslimit from "../../images/jobslimit.png"
import jobsstats from "../../images/jobsstats.png"

export default function Jobs() {
  const nav = useNavigate()

  return (
    <Page>
      <h1>Jobs</h1>
      <div className={s.content}>
        <span>Jobs are in-game roles you can join using the command: <span className={s.command}>/jobs browse</span></span>
        <span>Which will show you the following menu:</span>
        <img alt="" src={jobsbrowse} className={s.img} loading="lazy" />
        <span>Here, you can select a job. By default, you can join up to two jobs. You can increase this limit by purchasing <span className="link" onClick={() => nav("/ranks")}>In-Game Ranks</span><span>.</span></span>
        <span>Jobs grant you income and experience for completing basic in-game tasks. For example, the builder job gets income and experience for placing a variety of
          blocks.</span>
        <span>You can see what actions are rewarded with experience and income by right-clicking the jobs on the menu above.</span>
        <span>Income from your jobs is added directly to your balance which you can view by typing: <span className={s.command}>/bal</span></span>
        <img alt="" src={balance} className={s.img} loading="lazy" />
        <span>There is an hourly cap on how much you can earn from all of your jobs. You can view this limit with: <span className={s.command}>/jobs limit</span></span>
        <img alt="" src={jobslimit} className={s.img} loading="lazy" />
        <span>Earning experience in your job allows you to level it up. Levelling up your job increases the amount of money you earn from it and your hourly income
          limit.</span>
        <span>You can view your current job levels and the experience required to reach the next level by using: <span className={s.command}>/jobs stats</span></span>
        <img alt="" src={jobsstats} className={s.img} loading="lazy" />
        <span>You can leave one of your jobs by using: <span className={s.command}>/jobs leave [job]</span></span>
        <span>You can re-join a job you've left but your total experience will be reduced by 1/3rd of what you had when you last had the job.</span>
        <div className={s.tips}>
          <h4>Tips</h4>
          <span>To earn more money in a given hour, it's a good idea to raise your jobs income limit. Since this is affected by and shared between all of your jobs,
            a great way to do this is to join more jobs and level them up through the early levels that require less exp than your higher-level jobs, even if
            you only plan on using one job for most of your income.</span>
          <span>Jobs are there to reward you for your favourite way of playing the game but if you're looking to hit the economy grind, the best jobs for you are
            the ones that aren't limited by needing resources to do.
          </span>
        </div>
        <div className={s.nerdAlert}>
          <h4>Nerd Statistics</h4>
          <span>The equation that determines your hourly income limit is: <span className={s.command}>1250 + 8.5 * totalLevel</span></span>
          <span>The equation that determines how much money you earn per level is: <span className={s.command}>2 * ( baseIncome * ( 1 + ( jobLevel * 0.04 ) ) )</span></span>
          <span>The equation that determines how much experience you need to level up is: <span className={s.command}>10 * ( jobLevel ) + ( jobLevel ^ 2 * 4 )</span></span>
        </div>
      </div>
    </Page>
  )
}
