import Page from '../../components/page/Page'
import s from "./Error.module.scss"

export default function NotFound() {
  return (
    <Page>
      <h1>404</h1>
      <div className={s.content}>
        <span>Page not found.</span>
      </div>
    </Page>
  )
}
