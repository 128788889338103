import { Route, Routes } from 'react-router-dom'
import NotFound from './pages/Error/NotFound'
import Rule1 from './pages/Rules/Rule1'
import Rule2 from './pages/Rules/Rule2'
import Rule3 from './pages/Rules/Rule3'
import Rule4 from './pages/Rules/Rule4'
import Rule5 from './pages/Rules/Rule5'
import Rule6 from './pages/Rules/Rule6'
import Rule7 from './pages/Rules/Rule7'
import Rule8 from './pages/Rules/Rule8'
import Rule9 from './pages/Rules/Rule9'
import Rule10 from './pages/Rules/Rule10'
import Rules from './pages/Rules/Rules'
import Layout from './layout/Layout'
import Homepage from './pages/Homepage/Homepage'
import Store from './pages/Store/Store'
import ThankYou from './pages/Store/ThankYou'
import Terms from './pages/Store/Terms'
import Download from './pages/Download/Download'
import Commands from './pages/Info/Commands'
import Staff from './pages/Info/Staff'
import Elytra from './pages/Info/Elytra'
import Potm from './pages/Info/Potm'
import Jobs from './pages/Info/Jobs'
import Quests from './pages/Info/Quests'
import Ranks from './pages/Info/Ranks'
import DynamicPricing from './pages/Info/DynamicPricing'
import Policies from './pages/Docs/Policies'
import TownHandbook from './pages/Docs/TownHandbook'
import Dynmap from './pages/Dynmap/Dynmap'
import PassGen from './pages/PasswordGenerator/PassGen'
import { GettingStarted } from './pages/GettingStarted'
import Wishes from './pages/Info/Wishes'

export default function App() {
  return (
    <Layout>
      <Routes>
        <Route path='/404' element={<NotFound />}/>
        <Route path="/" element={<Homepage />} />
        <Route path="/new" element={<GettingStarted />} />
        <Route path="/store" element={<Store />} />
        <Route path="/store/thankyou" element={<ThankYou />} />
        <Route path="/store/terms" element={<Terms />} />
        <Route path="/rules" element={<Rules />} />
        <Route path="/docs/policies" element={<Policies />} />
        <Route path="/docs/townhandbook" element={<TownHandbook />} />
        <Route path="/download" element={<Download />} />
        <Route path="/info/commands" element={<Commands />} />
        <Route path="/info/ranks" element={<Ranks />} />
        <Route path="/info/jobs" element={<Jobs />} />
        <Route path="/info/quests" element={<Quests />} />
        <Route path="/info/elytra" element={<Elytra />} />
        <Route path="/info/potm" element={<Potm />} />
        <Route path="/info/staff" element={<Staff />} />
        <Route path="/info/dynamicpricing" element={<DynamicPricing />} />
        <Route path="/info/wishes" element={<Wishes />} />
        <Route path="/rules/1" element={<Rule1 />} />
        <Route path="/rules/2" element={<Rule2 />} />
        <Route path="/rules/3" element={<Rule3 />} />
        <Route path="/rules/4" element={<Rule4 />} />
        <Route path="/rules/5" element={<Rule5 />} />
        <Route path="/rules/6" element={<Rule6 />} />
        <Route path="/rules/7" element={<Rule7 />} />
        <Route path="/rules/8" element={<Rule8 />} />
        <Route path="/rules/9" element={<Rule9 />} />
        <Route path="/rules/10" element={<Rule10 />} />
        <Route path="/dynmap" element={<Dynmap />} />
        <Route path="/passwordgenerator" element={<PassGen />} />
        <Route path="/passgen" element={<PassGen />} />
        
        <Route path='*' element={<NotFound />}/>
      </Routes>
    </Layout>
  )
}
