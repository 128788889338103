import Page from '../../components/page/Page'
import s from "./Info.module.scss"

export default function Potm() {
  return (
    <Page>
      <h1>Player of the Month</h1>
      <div className={s.content}>
        <span>Player of the Month is a way for us to recognize members of the community who greatly contribute to making this the fun and friendly community it is.</span>
        <span>These contributions can be anything from random acts of kindness to guiding new players. Throughout the month you can nominate the player who you think is most deserving of the title by doing <span className={s.command}>/potm [player] [why they deserve it]</span> in-game.</span>
        <span>At the end of the month, every player with at least 2 nominations is put into a poll on our <span className="link" onClick={() => window.open("https://discord.gg/mfC2nS8")}>Discord Server</span> where everyone votes on who they think deserves it the most. Then, the person with the most votes gets to wear the [PotM] tag in-game for the next month!</span>
        <span></span>
        <span><i>Do not try to influence other's decisions on who to nominate or vote for - be it you or someone else. People should be nominated because they have demonstrated they are deserving of the title, not because they asked people to vote for them. Violating this could disqualify you from future participation in PotM.</i></span>
      </div>
    </Page>
  )
}
