import Page from '../../components/page/Page'
import s from "./Info.module.scss"
import quests_main_page from "../../images/quests_main_page.png"
import quests_group from "../../images/quests_group.png"

export default function Jobs() {
  return (
    <Page>
      <h1>Quests</h1>
      <div className={s.content}>
        <span>Quests are in-game tasks associated with <a href="/info/jobs" className="link">Jobs</a>. You can view your quests with <span className={s.command}>/quests</span></span>
        <img alt="" src={quests_main_page} className={s.img} loading="lazy" />
        <span>Here you can view your currently assigned quests including which job they're from, what exactly you need to do, and the rewards!</span>
        <span>If you don't like a quest you were assigned, you can skip it with the [X] button, but this will give you a 10% penalty on all quests for the next 15 minutes!</span>
        <span>Quests with a green '*' next to the task represent a group. This means there are multiple ways to complete the quest. You can hover over these to view the group.</span>
        <img alt="" src={quests_group} className={s.img} loading="lazy" />
        <h4>Streaks</h4>
        <span>Every day you complete at least 3 quests, you will continue your quest streak!</span>
        <span>Each day earns you a bonus that you can check in the bottom right of <span className={s.command}>/quests</span>. The bonus is about 3% per day - at least for the first few days. Once you start getting your streak in the tens or hundreds, the bonus per day goes down a bit. (See nerd statistics below)</span>
        <span>You can also earn <span className={s.command}>normal crate keys</span> and <span className={s.command}>event crate keys</span> by reaching certain milestones in your streak!</span>
        <span>If you miss a day of your streak it's not the end of the world! Your streak will only reset once you reach 3 strikes. Each day you do not complete your quests, you get a strike, and each day you do, a strike is removed.</span>
        <span>You can always check your number of strikes at the bottom left of <span className={s.command}>/quests</span> next to your streak count. If there's nothing there, you have no strikes.</span>
        <div className={s.nerdAlert}>
          <h4>Nerd Statistics</h4>
          <span>Streak bonus equation: <span className={s.command}>log(questStreak / 4 + 3) + 0.52287874528</span></span>
          <span>Reward Factor: <span className={s.command}>0.0075 * jobLevel + 0.75</span></span>
        </div>
      </div>
    </Page>
  )
}
