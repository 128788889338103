import Page from '../../components/page/Page'
import s from "./Rules.module.scss"

export default function Rule8() {
  return (
    <Page>
      <h1>Rule 8 - No Profiteering</h1>
      <div className={s.content}>
        <span>We understand that this rule is a little complicated and you'll be pleased to know that this rarely ever applies to anyone. This rule only exists to protect you and others from people who would scam you out of your in-game money, or worse, your real money.</span>
        <span>For most players, we just ask that you write down the details of work you pay in-game currency to do for you, such as in a book in-game.</span>
        <span>As an alternate way of understanding it, rule 8 can be broken down into sub-rules:</span>
        <span><span className={s.subrule}>8.1</span> Don't sell in-game items for real money or for anything that equates to real money, such as items from the webstore.</span>
        <span><span className={s.subrule}>8.2</span> Don't use an alt account to bypass limitations such as dynamic /shop pricing or the jobs income limit.</span>
        <span><span className={s.subrule}>8.3</span> Don't deliberately clutter /ah with listings that nobody will buy.</span>
        <span><span className={s.subrule}>8.4</span> The terms of all forms of in-game labour in exchange for in-game currency or other forms of in-game wealth and the consent to those terms from all parties involved must be documented in a way that is easily accessible to all parties involved.</span>
        <span><span className={s.subrule}>8.5</span> Loans of in-game currency where the lender stands to make profit from the loan aren't protected under Rule 1's scamming clause. Lenders are expected to find their own consequences for non-payment of their loans.</span>
      </div>
    </Page>
  )
}
