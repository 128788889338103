import { MouseEventHandler } from "react";
import Package from "../Interfaces/Package"
import s from "./Package.module.scss"
import sStore from "../Store.module.scss"

interface CardProps {
  pkg: Package;
  packageDetails: boolean;
  isListView?: boolean;
  currency: String | null;
  onClick?: (name: string) => void;
  color?: String;
  color_hover?: String;
  buyForm?: React.JSX.Element;
  resetUsername?: MouseEventHandler<HTMLButtonElement>;
}

export default function Card(props: CardProps) {
  const buyable = !props.pkg.limit_one || !props.pkg.owned;
  return (
    <div
      key={props.pkg.id}
      className={`${s.packageCard} ${props.packageDetails ? s.packageCardPackageViewer : (props.isListView ? s.packageCardList : s.packageCardGrid)}`}
      onClick={() => {
        if (!props.onClick) return;
        props.onClick(props.pkg.name);
      }}
      style={{ backgroundColor: `${props.color && props.color}` }}
      onMouseEnter={(e) => e.currentTarget.style.backgroundColor = `${props.color_hover ? props.color_hover : props.pkg.color_hover ? props.pkg.color_hover : props.packageDetails ? '#ffffff' : '#dddddd'}`} // Change color on hover
      onMouseLeave={(e) => e.currentTarget.style.backgroundColor = `${props.color ? props.color : '#ffffff'}`} // Revert color on mouse leave
    >
      <img
        src={`https://atownyserver.com/resources/store/icons/${props.pkg.id}.png`}
        alt={`${props.pkg.name} icon`}
        className={`${s.packageIcon} ${!props.packageDetails && props.isListView && s.packageIconSmall}`}
      />
      {(!props.packageDetails || !buyable) && <div className={`${s.packageInfoGrid} ${!props.packageDetails && props.isListView && s.packageInfoList}`}>
        <span className={s.packageName}>{props.pkg.name}</span>
        {props.pkg.name !== 'Custom Amount' && <span className={s.packagePrice}>
          <div>
            {(!buyable || props.pkg.discount_f) && <span className={s.packagePriceDiscount}>{!buyable ? 'PURCHASED' : <>{props.pkg.discount_f}{props.pkg.duration && <>/{props.pkg.duration.toLowerCase()}</>}</>} </span>}
          </div>
          <div>
            <span className={`${(!buyable || props.pkg.discount_f) && s.packagePriceDiscounted}`}>{props.pkg.cost_f}</span> {props.currency}
            {props.pkg.duration && <span>/{props.pkg.duration.toLowerCase()}</span>}
          </div>
        </span>}
      </div>}
      {props.packageDetails && buyable && props.buyForm && props.buyForm}
      {props.packageDetails && props.resetUsername && !buyable && props.pkg.name !== 'Free Rank' && <button className={sStore.submitButton} onClick={props.resetUsername}>Buy For Someone Else</button>}
    </div>
  );
}