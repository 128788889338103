import { ReactNode } from "react"
import s from "./Page.module.scss"

type Props = {
  children: ReactNode
}

export default function Page({ children }: Props) {
  return (
    <div className={s.page}>
      <div className={s.content}>
        <div className={s.children}>
          {children}
        </div>
        <div className={s.disclaimer}>
          <p>
            A Towny Server. <a href="mailto:atownyservermc@gmail.com">Email Us.</a>
          </p>
          <p>
            NOT AN OFFICIAL MINECRAFT PRODUCT OR SERVICE. NOT APPROVED BY OR ASSOCIATED WITH MOJANG OR MICROSOFT.
          </p>
        </div>
      </div>
    </div>
  )
}
